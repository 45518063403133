import React from 'react';
import { Link } from 'react-router-dom';

// Internal
import Typography from './Typography';

// Styles
import styles from './FullPageSplash.module.scss';
import { Button, Space } from 'antd';

interface FullPageSplashProps {
  actions: React.ReactNode;
  backgroundStyles: React.CSSProperties;
  title: React.ReactNode;
  subTitle: React.ReactNode;
  pageTitle?: React.ReactNode;
}

export default function FullPageSplash({
  actions,
  backgroundStyles,
  title,
  subTitle,
  pageTitle,
}: FullPageSplashProps) {
  return (
    <div className={styles.fullPageSplash}>
      <div className={styles.content}>
        <div className={styles.spacer}></div>

        <div className={styles.text}>
          <Typography.Text color="primary" strong>
            {pageTitle}
          </Typography.Text>

          <Typography.Title level={1}>{title}</Typography.Title>

          <Typography.Title level={3} color="tertiary">
            {subTitle}
          </Typography.Title>

          <Space style={{ marginTop: '3rem' }}>{actions}</Space>
        </div>

        <div className={styles.spacer}></div>
      </div>

      <div className={styles.bg} style={backgroundStyles}></div>
    </div>
  );
}
