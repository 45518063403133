import React, { useCallback, useMemo, useState, useEffect } from 'react';

// Integral
import {
  AccountingIntegration,
  AccountingIntegrationConnectionDto,
  getAccountingConnections,
} from '@/api/accounting-integrations';

// Images
import QBO_LOGO from '../img/logos_company/Quickbooks@4x.png';
import XERO_LOGO from '../img/logos_company/Xero@4x.png';

type OnboardConnectionDetailsProviderProps = { children: React.ReactNode };

const OnboardConnectionDetailsStateContext = React.createContext<
  | {
      accountingPlatformName: string;
      accountingPlatformLogo: string;
      accountingIsConnected?: boolean;
      reFetchConnections: () => void;
    }
  | undefined
>(undefined);

function OnboardConnectionDetailsProvider({ children }: OnboardConnectionDetailsProviderProps) {
  const [accountingConnection, setAccountingConnection] =
    useState<AccountingIntegrationConnectionDto | null>(null);

  const accountingIsConnected = useMemo(() => !!accountingConnection, [accountingConnection]);

  const accountingPlatformName = useMemo(() => {
    return accountingConnection?.type ?? 'ERP';
  }, [accountingConnection]);

  const accountingPlatformLogo = useMemo(() => {
    return accountingConnection
      ? accountingConnection.type === AccountingIntegration.Quickbooks
        ? QBO_LOGO
        : accountingConnection.type === AccountingIntegration.Xero
          ? XERO_LOGO
          : QBO_LOGO
      : QBO_LOGO;
  }, [accountingConnection]);

  const reFetchConnections = useCallback(() => {
    getAccountingConnections().then((connections) => {
      setAccountingConnection(connections[0]);
    });
  }, []);

  useEffect(() => {
    reFetchConnections();
  }, [reFetchConnections]);

  const value = {
    accountingPlatformName,
    accountingPlatformLogo,
    accountingIsConnected,
    reFetchConnections,
  };

  return (
    <OnboardConnectionDetailsStateContext.Provider value={value}>
      {children}
    </OnboardConnectionDetailsStateContext.Provider>
  );
}

function useOnboardConnectionDetails() {
  const context = React.useContext(OnboardConnectionDetailsStateContext);
  if (context === undefined) {
    throw new Error(
      'useOnboardConnectionDetails must be used within a OnboardConnectionDetailsProvider',
    );
  }
  return context;
}

export { OnboardConnectionDetailsProvider, useOnboardConnectionDetails };
