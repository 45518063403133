import {
  addMinutes,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  getQuarter,
  getYear,
  isAfter,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  sub,
} from 'date-fns';
import { EN_DASH } from '.';

/**
 * Time formatting
 * https://date-fns.org/docs/format
 */
export const AMPM_FORMAT = 'aaa'; // "Ante Meridiem" and "Post Meridiem" (ie. "am" and "pm")
export const TIME_FORMAT = 'h:mm'; // 12-hour format

export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_FORMAT__WITH_TIME = `${DATE_FORMAT}, ${TIME_FORMAT} ${AMPM_FORMAT}`;
export const DATE_FORMAT__FOR_URLS = 'yyyy-MM-dd';

export const DATE_FORMAT__SENTENCE_SHORT = 'MMM d';
export const DATE_FORMAT__SENTENCE_MID = `${DATE_FORMAT__SENTENCE_SHORT}, yyyy`;
export const DATE_FORMAT__SENTENCE_LONG = `iii, ${DATE_FORMAT__SENTENCE_SHORT}, yyyy`;

export const DATE_FORMAT__TIMESTAMP = `${DATE_FORMAT__SENTENCE_MID}, hh:mm aaa`;

// Tables & tabular numbers
export const DATE_FORMAT_TABULAR__DATE_ONLY = 'MMM dd, yyyy';
export const DATE_FORMAT_TABULAR__TIME_ONLY = `h:mm:ss ${AMPM_FORMAT}`;
export const DATE_FORMAT_TABULAR =
  DATE_FORMAT_TABULAR__DATE_ONLY + ' ' + DATE_FORMAT_TABULAR__TIME_ONLY;

/**
 * Gets current date offsetted to the UTC timezone
 * @returns date object representing current date/time in UTC timezone
 */
export function getCurrentDateInUTC() {
  return offsetDateToUTC(new Date());
}

// Rounds up
export function convertSecondsToMinutes(seconds: number) {
  return Math.ceil(seconds / 60);
}

/**
 * Parses UTC date string and offsets to local time
 * @param dateString
 * @returns new date object
 */
export function parseUTCDateString(dateString: string) {
  return offsetDateToUTC(new Date(dateString));
}

/**
 * Offsets a date object to UTC timzeone, then formats it
 * @param date
 * @param formatString
 * @returns formatted string
 */
export function formatDateInUTC(date?: Date | string | null, formatString?: string): string {
  if (date === undefined || date === null) {
    return EN_DASH;
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  if (typeof date.getTime !== 'function' || isNaN(date.getTime())) {
    return EN_DASH;
  }

  return format(addMinutes(date, date.getTimezoneOffset()), formatString || DATE_FORMAT);
}

/**
 * Gets timezone offset of current browser time
 * @returns offset in milliseconds
 */
export function getUTCOffsetInMilliseconds() {
  return new Date().getTimezoneOffset() * 60000;
}

/**
 * Offsets a date object to UTC timzeone
 * @param date
 * @returns new date object
 */
export function offsetDateToUTC(date: Date) {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

/**
 * Offsets a date to local date timezone
 * @param dateString
 * @returns new date object
 */
export function offsetUtcToLocalDate(date: Date) {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

/**
 * Gets the start of day `date` object in UTC timzeone
 * @param dateString
 * @returns new date object
 */
export const getStartOfUtcDay = (date: Date): Date => {
  const dateString = date.toISOString().split('T')[0]; // Gets 'YYYY-MM-DD'
  const startOfDayUTCString = `${dateString}T00:00:00.000Z`; // Constructs a full UTC datetime string at start of day

  return new Date(startOfDayUTCString);
};

export function getDefaultRangePickerRanges(
  {
    maxDate,
    useUTC,
  }: {
    maxDate?: Date;
    useUTC?: boolean;
  } = { useUTC: true },
) {
  const _currentDate = useUTC ? getCurrentDateInUTC() : new Date();
  const _endDate = maxDate
    ? isAfter(_currentDate, maxDate)
      ? maxDate
      : _currentDate
    : _currentDate;

  const _endOfLastMonth = endOfMonth(
    sub(_currentDate, {
      months: 1,
    }),
  );

  const _endOfLastYear = endOfYear(
    sub(_currentDate, {
      years: 1,
    }),
  );

  return {
    'This Month': [startOfMonth(_currentDate), _endDate] as any,
    'Last Month': [
      startOfMonth(
        sub(_currentDate, {
          months: 1,
        }),
      ),
      maxDate ? (isAfter(_endOfLastMonth, maxDate) ? maxDate : _endOfLastMonth) : _endOfLastMonth,
    ] as any,
    'This Week': [startOfWeek(_currentDate), _endDate] as any,
    'This Year': [startOfYear(_currentDate), _endDate] as any,
    'Last Year': [
      startOfYear(
        sub(_currentDate, {
          years: 1,
        }),
      ),
      maxDate ? (isAfter(_endOfLastYear, maxDate) ? maxDate : _endOfLastYear) : _endOfLastYear,
    ] as any,
  };
}

export function getDefaultRangePickerPresets(configs?: { disableAfterDate?: Date }) {
  const today = getCurrentDateInUTC();

  // If a disableAfterDate is provided, ensure today doesn't go beyond it
  const effectiveToday =
    configs?.disableAfterDate && today > configs.disableAfterDate
      ? configs.disableAfterDate
      : today;

  const currentQuarter = [startOfQuarter(effectiveToday), effectiveToday];
  const previousQuarter = [
    startOfQuarter(
      sub(effectiveToday, {
        months: 3,
      }),
    ),
    endOfQuarter(
      sub(effectiveToday, {
        months: 3,
      }),
    ),
  ];

  const secondPreviousQuarter = [
    startOfQuarter(
      sub(effectiveToday, {
        months: 6,
      }),
    ),
    endOfQuarter(
      sub(effectiveToday, {
        months: 6,
      }),
    ),
  ];

  const thirdPreviousQuarter = [
    startOfQuarter(
      sub(effectiveToday, {
        months: 9,
      }),
    ),
    endOfQuarter(
      sub(effectiveToday, {
        months: 9,
      }),
    ),
  ];

  const startOfLastYear = startOfYear(
    sub(effectiveToday, {
      years: 1,
    }),
  );

  return [
    {
      label: 'This Month',
      value: [startOfMonth(effectiveToday), effectiveToday],
    },
    {
      label: 'Last Month',
      value: [
        startOfMonth(
          sub(effectiveToday, {
            months: 1,
          }),
        ),
        endOfMonth(
          sub(effectiveToday, {
            months: 1,
          }),
        ),
      ],
    },
    {
      label: `Q${getQuarter(currentQuarter[0])} ${getYear(currentQuarter[0])}`,
      value: currentQuarter,
    },
    {
      label: `Q${getQuarter(previousQuarter[0])} ${getYear(previousQuarter[0])}`,
      value: previousQuarter,
    },
    {
      label: `Q${getQuarter(secondPreviousQuarter[0])} ${getYear(secondPreviousQuarter[0])}`,
      value: secondPreviousQuarter,
    },
    {
      label: `Q${getQuarter(thirdPreviousQuarter[0])} ${getYear(thirdPreviousQuarter[0])}`,
      value: thirdPreviousQuarter,
    },
    {
      label: getYear(startOfYear(effectiveToday)),
      value: [startOfYear(effectiveToday), effectiveToday],
    },
    {
      label: getYear(startOfLastYear),
      value: [
        startOfLastYear,
        endOfYear(
          sub(effectiveToday, {
            years: 1,
          }),
        ),
      ],
    },
  ] as any[];
}

/**
 * Returns the default available dates based on the current date and an optional offset.
 * @param current The current date.
 * @param offsetFromToday The number of days to offset from the current date. Defaults to 1 if not provided.
 * @returns A boolean indicating whether the current date is greater than the calculated date.
 */
export function getDefaultAvailableDates(current: Date, offsetFromToday?: number) {
  const currentDate = sub(getCurrentDateInUTC(), {
    days: offsetFromToday ?? 1,
  });
  return current && current > currentDate;
}

/**
 * Determines if a given date is in the future or today, based on the current date and an optional offset.
 * @param current The date to evaluate.
 * @param offsetFromToday The number of days to offset from the current date. Defaults to 0 if not provided.
 * @returns A boolean indicating whether the provided date is valid (i.e., today or in the future).
 */
export function isDateInFutureOrToday(current: Date, offsetFromToday: number = 0): boolean {
  const todayUTC = getCurrentDateInUTC().setHours(0, 0, 0, 0); // Normalize to midnight UTC
  const validDate = sub(todayUTC, { days: offsetFromToday });
  return current >= validDate;
}
