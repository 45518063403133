// React
import { useState, useEffect, useCallback } from 'react';

// Internal
import { useOrganizations } from '../contexts/OrganizationsContext';

// Types
import { PermissionTypes } from '@/api/__types/roles.types';
import { useOrgMember } from '@/api/organization';

export type CheckPermissionFunction = (permission?: PermissionTypes) => boolean;

const usePermission = (
  permission?: PermissionTypes,
): {
  hasPermission: boolean | undefined;
  checkPermission: CheckPermissionFunction;
} => {
  const [hasPermission, setHasPermission] = useState<boolean | undefined>();
  const { organization } = useOrganizations();
  const { data: memberRoleAndPermission } = useOrgMember(organization);

  useEffect(() => {
    if (memberRoleAndPermission) {
      const hasPermission = checkPermission(permission);
      setHasPermission(hasPermission);
    }
  }, [memberRoleAndPermission]);

  const checkPermission = useCallback(
    (permission?: string) => {
      if (permission === undefined) return true;
      return !!memberRoleAndPermission?.permissions.some((p) => p === permission);
    },
    [memberRoleAndPermission],
  );

  if (!permission) {
    return { hasPermission: true, checkPermission };
  }

  return { hasPermission, checkPermission };
};

export default usePermission;
