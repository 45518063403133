import BigNumber from 'bignumber.js';
import { pick } from 'lodash-es';

export enum WalletTempCastingTypes {
  TOKEN = 'token',
  COLLECTION = 'collection',
}

export const addBalanceDataToAccounts = (
  accounts: any[],
  accountsByDay = false,
  alsoAddCollectionData = false,
) => {
  accounts?.forEach((acc) => {
    acc.tempBalanceCasting = [];

    if (accountsByDay) {
      acc.tokens.forEach((token: any) => {
        acc.tempBalanceCasting?.push({
          isTempCasted: true,
          tempCastType: WalletTempCastingTypes.TOKEN,
          id: token.tokenId,
          balance: new BigNumber(token.usdAmount || 0).toNumber(),
          chain: token.chain,
          symbol: token.symbol,
          name: token.name,
          iconUrl: token.iconUrl,
          amount: new BigNumber(token.amount || 0).toNumber(),
          cost: new BigNumber(token.costBasis || 0).toNumber(),
        });
      });

      acc.totalCost = acc.tokens?.reduce(
        (s: number, b: any) => s + (new BigNumber(b.costBasis).toNumber() || 0),
        0,
      );
    } else {
      acc.balances.forEach((balance: any) => {
        acc.tempBalanceCasting?.push({
          isTempCasted: true,
          tempCastType: WalletTempCastingTypes.TOKEN,
          id: balance.id,
          name: balance.name,
          parentBookAccount: acc.bookAccount,
          availableBalance: balance.fiatAmount,
          balances: [
            {
              id: `not_used_${balance.id}`,
              fiatAmount: balance.fiatAmount,
              symbol: balance.symbol,
              icon: balance.icon,
              amount: balance.amount,
              bookAccount: balance.bookAccount,
            },
          ],
          updatedAt: balance.updatedAt,
          symbol: balance.symbol,
          bookAccount: balance.bookAccount,
          unrealizedReturn: balance.unrealizedReturn,
          unrealizedReturnPercent: balance.unrealizedReturnPercent,
        } as any);
      });
    }

    // Clear if nothing was added
    if (acc.tempBalanceCasting.length === 0) {
      delete acc.tempBalanceCasting;
    }
  });

  if (alsoAddCollectionData) {
    addCollectionDataToAccounts(accounts);
  }
};

export const addCollectionDataToAccounts = (accounts: any[]) => {
  accounts?.forEach((acc) => {
    if (!acc.tempBalanceCasting) {
      acc.tempBalanceCasting = [];
    }

    acc.collections.forEach((collection: any) => {
      acc.tempBalanceCasting?.push({
        isTempCasted: true,
        tempCastType: WalletTempCastingTypes.COLLECTION,
        ...pick(collection, [
          'amount',
          'collectionName',
          'thumbnailUrl',
          'usdCostBasis',
          'valuationUsdAmount',
          'usdGainLoss',
        ]),
      });
    });

    acc.totalCost = acc.tokens?.reduce(
      (s: number, b: any) => s + (new BigNumber(b.costBasis).toNumber() || 0),
      0,
    );
  });
};

export const addBalanceAndCollectionDataToAccounts = (accounts: any[], accountsByDay = false) => {
  addBalanceDataToAccounts(accounts, accountsByDay, true);
};
