import React from 'react';

// Internal
import { VestingContract, getVestingContracts } from '@/api/vesting';

type VestingContractsProviderProps = { children: React.ReactNode };

const VestingContractsStateContext = React.createContext<
  | {
      vestingContracts: VestingContract[] | null;
      reFetchVestingContracts: Function;
      loading: boolean;
    }
  | undefined
>(undefined);

function VestingContractsProvider({ children }: VestingContractsProviderProps) {
  const [vestingContracts, setVestingContracts] = React.useState<VestingContract[] | null>(null);
  const [loading, setLoading] = React.useState(false);

  const fetchVestingContracts = () => {
    setLoading(true);

    // Pass in the current unix epoch (otherwise shows the state at the moment of scheduling)
    getVestingContracts({ asOf: Math.floor(Date.now() / 1000) })
      .then(({ body }) => {
        setVestingContracts(body.data);
      })
      .catch((err) => {
        console.error('Error fetching vesting contracts', err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(fetchVestingContracts, []);

  const value = {
    vestingContracts: vestingContracts,
    reFetchVestingContracts: fetchVestingContracts,
    loading,
  };

  return (
    <VestingContractsStateContext.Provider value={value}>
      {children}
    </VestingContractsStateContext.Provider>
  );
}

function useVestingContracts() {
  const context = React.useContext(VestingContractsStateContext);
  if (context === undefined) {
    throw new Error('useVestingContracts must be used within a VestingContractsProvider');
  }
  return context;
}

export { VestingContractsProvider, useVestingContracts };
