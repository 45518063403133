import { useState } from 'react';
import { Link } from 'react-router-dom';

// Internal
import Typography from '../../components/Typography';
import { sendPasswordResetEmail } from '@/api/auth';

// Antd
import { Form, Input, Button, Space, message } from 'antd';

// Icons
import { ArrowLeft, Email } from '@carbon/icons-react';

// Styles
import styles from './login.module.scss';

function ForgotPassword() {
  const [loading, setLoading] = useState(false);

  const onFinish = (values: any) => {
    setLoading(true);
    sendPasswordResetEmail({
      email: values.email,
    })
      .then(({ body }) => {
        message.success(body.data);
      })
      .catch((code) => {
        console.log('Error', code);
        message.error('Something went wrong. Please try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Space direction="vertical" className={styles.loginPage__container__form__methods}>
        <Link to="/" style={{ display: 'block', marginBottom: '1rem' }}>
          <ArrowLeft
            style={{
              height: '1.15rem',
              marginTop: '-0.15rem',
              marginLeft: '-0.45rem',
              marginRight: '0.5rem',
            }}
          />

          <Typography.Text strong inline>
            Login
          </Typography.Text>
        </Link>

        <Typography.Title level={1}>Forgot password?</Typography.Title>

        <Typography.Text color="tertiary" style={{ marginBottom: '1rem' }}>
          We'll send you a link to reset your password
        </Typography.Text>

        <Form onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Missing email address' },
              { type: 'email', message: 'Enter a valid email' },
            ]}
            style={{ paddingBottom: '1.5rem' }}
          >
            <Input autoFocus prefix={<Email />} placeholder="Enter your email" />
          </Form.Item>

          <Button type="primary" htmlType="submit" loading={loading}>
            Reset Password
          </Button>
        </Form>

        <div className={styles.loginPage__container__form__signup__message}>
          <Typography.Text color="tertiary">
            Don't have an account? <Link to="/register">Register</Link> now.
          </Typography.Text>
        </div>
      </Space>
    </>
  );
}
export default ForgotPassword;
