// react-query
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// Internal
import { formatEntityCount } from '@/utils';
import { handleMutationErrorDefault } from '..';
import {
  GetTokensDto,
  TokenCurrentPriceDto,
  UniqAsset,
  UpdateTokenDto,
  UpdateTokensDto,
} from './__types/tokens.types';
import { TokenFilterQuery } from './filters';
import { ApiResponse, getRequest, patchQueryRequest, patchRequest } from './request';

// Antd
import { message } from 'antd';

// Export types
export * from './__types/tokens.types';

// Query keys
export const tokensQueryKeys = {
  all: ['tokens'] as const,
  lists: () => [...tokensQueryKeys.all, 'list'] as const,
  tokensLists: () => [...tokensQueryKeys.lists(), 'tokens-lists'] as const,
  tokensList: (dto: GetTokensDto) => [...tokensQueryKeys.tokensLists(), dto] as const,
  tokenFilterLists: () => [...tokensQueryKeys.lists(), 'tokens-filter'] as const,
  tokenFilterList: (dto: TokenFilterQuery) => [...tokensQueryKeys.tokenFilterLists(), dto] as const,
  tokenCurrentPrices: () => [...tokensQueryKeys.lists(), 'tokens-current-price'] as const,
  tokenCurrentPrice: (tokenId: number) =>
    [...tokensQueryKeys.tokenCurrentPrices(), tokenId] as const,
};

export const getAssetList = (filter: GetTokensDto) =>
  getRequest<ApiResponse<UniqAsset[]>>('/tokens', filter);
export const useGetTokens = (dto: GetTokensDto = { isSpam: false }) => {
  const query = useQuery<UniqAsset[], Error>({
    queryKey: tokensQueryKeys.tokensList(dto),
    queryFn: async () => {
      const { body } = await getAssetList(dto);
      return body.data;
    },
  });

  return {
    ...query,
    allTokens: query.data,
    tokensWithSymbols: query.data?.filter((token) => !!token.symbol),
  };
};

export const updateToken = (dto: UpdateTokenDto) =>
  patchQueryRequest<ApiResponse<string>>(`/tokens/inventory`, dto);

export const updateTokens = (dto: UpdateTokensDto) =>
  patchRequest<ApiResponse<string>>(`/tokens/inventories`, dto);
export const useUpdateTokens = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dto: UpdateTokensDto) => {
      const { body } = await updateTokens(dto);
      return body.data;
    },

    onMutate: async (variables) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: tokensQueryKeys.tokensList({ isSpam: undefined }),
      });

      // Snapshot the previous value
      const previousValue = queryClient.getQueryData(
        tokensQueryKeys.tokensList({ isSpam: undefined }),
      ) as UniqAsset[];

      // Optimistically update to the new value
      const newValue = previousValue.map((token) => ({
        ...token,
        isSpam: variables.tokenIds.includes(token.tokenId) ? variables.isSpam : token.isSpam,
      }));

      queryClient.setQueryData(tokensQueryKeys.tokensList({ isSpam: undefined }), newValue);
      if (variables.tokenIds.length > 1) {
        message.success(
          `${formatEntityCount({ number: variables.tokenIds.length, entityName: 'token' })} marked as ${
            variables.isSpam ? '' : 'not'
          } spam`,
        );
      }

      // Return a context object with the snapshotted value
      return { previousValue };
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (error: any, _, context) => {
      queryClient.setQueryData(
        tokensQueryKeys.tokensList({ isSpam: undefined }),
        context?.previousValue,
      );

      handleMutationErrorDefault(error); // exported from the queryClient default options
    },
  });
};

export const getCurrentTokenPrice = (tokenId: number) =>
  getRequest<ApiResponse<TokenCurrentPriceDto>>('/tokens/current-price-by-token-id', { tokenId });
export const useGetCurrentTokenPrice = (tokenId: number) => {
  return useQuery<TokenCurrentPriceDto, Error>({
    queryKey: tokensQueryKeys.tokenCurrentPrice(tokenId),
    queryFn: async () => {
      const { body } = await getCurrentTokenPrice(tokenId);
      return body.data;
    },
  });
};
