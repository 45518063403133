// Internal
import Typography, { Label } from '../../components/Typography';

// Antd
import { Form, Input, Button, Space, message } from 'antd';

// Styles
import styles from './login.module.scss';
import React, { useEffect, useState } from 'react';
import LOGO from '../../img/logo/fill_primary.svg';
import { confirmPasswordReset, logout, verifyPasswordResetCode } from '../../services/firebase';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from '@/api/auth';

export default function ResetPassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const oobCode = searchParams.get('code');
    const email = searchParams.get('email');

    if (oobCode) {
      setCode(oobCode);
      verifyPasswordResetCode(oobCode)
        .then((result) => {
          form.setFieldsValue({
            email: result,
          });
        })
        .catch((error) => {
          console.error('Error verifying password reset code:', error);
          setExpired(true);
        });
    } else {
      // oobCode is mandatory, redirect if not present
      navigate('/');
    }

    if (email) {
      setEmail(email);
    } else {
      // email is mandatory, redirect if not present
      navigate('/');
    }
  }, []);

  const onFinish = (values: any) => {
    setLoading(true);
    confirmPasswordReset(code, values.password)
      .then(() => {
        // After successful reset, navigate to login page with reset state
        logout();
        navigate('/?reset=true');
      })
      .catch((error) => {
        console.error('Error resetting password:', error);
        message.error('Something went wrong. Please try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRequestNewLinkFinish = () => {
    setLoading(true);
    sendPasswordResetEmail({
      email: email,
    })
      .then(({ body }) => {
        message.success(body.data);
      })
      .catch((code) => {
        console.log('Error', code);
        message.error('Something went wrong. Please try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Space direction="vertical" className={styles.loginPage__container__form__methods}>
        <div className={styles.loginPage}>
          <div className={styles.loginPage__bg}>
            <div className={styles.loginPage__bg__glow1}></div>
            <div className={styles.loginPage__bg__glow2}></div>
          </div>

          <div className={styles.loginPage__container}>
            <div className={styles.loginPage__logo}>
              <img src={LOGO} alt="Integral" />
            </div>

            <div
              className={[styles.loginPage__section, styles.loginPage__container__form].join(' ')}
              style={{ padding: '3.5rem 4rem' }}
            >
              {!expired && (
                <>
                  <Typography.Title level={1} style={{ marginBottom: '2rem' }}>
                    Reset Password
                  </Typography.Title>
                  <Form form={form} onFinish={onFinish}>
                    <Label style={{ marginBottom: '6px', fontSize: '12px' }}>Email</Label>
                    <Form.Item
                      name="email"
                      initialValue="loading..."
                      style={{ marginBottom: '1rem' }}
                    >
                      <Input placeholder="Email address" disabled readOnly />
                    </Form.Item>

                    <Label style={{ marginBottom: '6px', fontSize: '12px' }}>New Password</Label>
                    <Form.Item
                      name="password"
                      validateTrigger={'onBlur'}
                      rules={[
                        { required: true, whitespace: false, message: 'At least 12 characters' },
                        { min: 12, message: 'At least 12 characters' },
                      ]}
                      style={{ paddingBottom: '1rem' }}
                    >
                      <Input autoFocus type="password" />
                    </Form.Item>

                    <Label style={{ marginBottom: '6px', fontSize: '12px' }}>
                      Repeat New Password
                    </Label>
                    <Form.Item
                      name="passwordConfirmation"
                      dependencies={['password']}
                      validateTrigger={'onBlur'}
                      rules={[
                        { required: true, whitespace: false, message: `Passwords don't match` },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(`Passwords don't match`);
                          },
                        }),
                      ]}
                      style={{ paddingBottom: '1rem' }}
                    >
                      <Input type="password" />
                    </Form.Item>

                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      style={{ marginTop: '1rem' }}
                    >
                      Reset Password
                    </Button>
                  </Form>
                </>
              )}

              {expired && (
                <>
                  <Typography.Title type="warning" style={{ marginBottom: '1rem' }}>
                    This link has expired
                  </Typography.Title>
                  <Typography.Text color="tertiary">
                    To continue, request a new link or log in to your Integral account
                  </Typography.Text>
                  <Form form={form} onFinish={onRequestNewLinkFinish}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      style={{ marginTop: '2rem' }}
                    >
                      Request New Link
                    </Button>

                    <Button onClick={() => navigate('/')} style={{ marginTop: '1rem' }}>
                      Log In
                    </Button>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
      </Space>
    </>
  );
}
