// Internal
import { postRequest } from './request';

export async function startOpenidConnection() {
  const startResult = await postRequest('/xero/start-openid-connection', {});
  // Use the current location as a redirect_uri to make it work in deploy preview and different domains.
  const authUri = new URL(startResult.body.data.authUri);
  authUri.searchParams.set('redirect_uri', `${window.location.origin}/xero-signup`);
  return authUri.toString();
}
