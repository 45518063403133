import { useCallback, useEffect, useState } from 'react';
// Button
import { Spin, message } from 'antd';
import PageLayout from '@/components/Shell/PageLayout';
import { startOpenidConnection } from '@/api/xero';
import { ApiError } from '@/api/request';
import { useSearchParams } from 'react-router-dom';
import styles from './xeroLeadForm.module.scss';

const LEAD_FORM_URL: string = 'https://forms.gle/ShNGRpSyUtaNtPaG9';

export default function XeroLeadForm() {
  const [loading, setLoading] = useState(true);

  const [searchParams] = useSearchParams() as any;
  const isSignUpDone = Boolean(searchParams.get('code'));

  const connect = useCallback(async () => {
    try {
      const url = await startOpenidConnection();
      setLoading(false);
      window.location.href = url;
    } catch (error: any) {
      message.error(
        (error instanceof ApiError && error.isClientError && error.message) ||
          'Something went wrong',
      );
    } finally {
      setLoading(false);
    }
  }, [setLoading]);
  useEffect(() => {
    if (!isSignUpDone) connect();
    else window.location.href = LEAD_FORM_URL;
  }, [connect, isSignUpDone]);

  return (
    <PageLayout hasMaxWidth hasBottomPadding={false} className={styles.spin}>
      <Spin spinning={loading}></Spin>
    </PageLayout>
  );
}
