// React
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// Internal
import { analyticsTracking } from '@/api/analytics';
import { getUser, IUser } from '@/api/auth';
import { IOrganization, useGetOrganizations } from '@/api/organization';
import { GLOBAL_SEARCH_PARAMS, SESSION_KEYS, STORAGE_KEYS } from '@/utils/constants';

// Antd
import { message } from 'antd';

// Firebase
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logout } from '@/services/firebase';

// Misc
import * as Sentry from '@sentry/react';
import { get } from 'lodash-es';
import LogRocket from 'logrocket';

const PUBLIC_ROUTES = [
  '',
  '/',
  '/register',
  '/forgot-password',
  '/reset-password',
  '/create-account',
  '/xero-signup',
];

const excludeUsers = ['demo@integral.xyz', 'testing@integral.xyz', 'demo@asgardtreasury.xyz'];

/**
 * Do any setup and initializing before the app renders
 */
const useAppLoad = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const orgIdSearchParam = searchParams.get(GLOBAL_SEARCH_PARAMS.orgId.key);

  const navigate = useNavigate();
  const location = useLocation();

  const [user, userLoading] = useAuthState(auth);
  const {
    data: organizations,
    isLoading: isGetOrganizationsLoading,
    isSuccess: isGetOrganizationsSuccess,
    isError: isGetOrganizationsError,
    error: errorForGetOrganizations,
  } = useGetOrganizations({ enabled: !!user });

  const [appLoading, setAppLoading] = useState(true);
  const [organization, setOrg] = useState<IOrganization | null>(null);
  const [locationState, setLocationState] = useState<any>(location.state);

  /**
   * Sets the current organization and performs necessary actions based on the organization change.
   * @param org - The organization to set. Pass `null` to clear the current organization.
   */
  const setOrganization = useCallback(
    (org: IOrganization | null) => {
      if (org?.id === organization?.id) {
        // If the orgIds match, the org details must have been modified. Must set accordingly.
        setOrg(org);
        return;
      }

      const orgId = org?.id;
      if (orgId) {
        analyticsTracking.group(org);
        window.sessionStorage.setItem(SESSION_KEYS.currentOrg, org?.id);
        // clear react query offline cache upon org switch
        const _lastOrg = window.localStorage.getItem(STORAGE_KEYS.lastOrg);
        if (!_lastOrg || _lastOrg !== orgId) {
          window.localStorage.removeItem(STORAGE_KEYS.reactQueryOfflineCache);
        }
      } else {
        window.sessionStorage.removeItem(SESSION_KEYS.currentOrg);
      }

      if (!appLoading) {
        if (get(window, 'location.href')) {
          const allowedParams = ['invite', 'create', 'reset'];
          const newURL = window.location.href.split('?')[0];
          const urlParams = new URLSearchParams(window.location.search);

          // Check if the parameter in the URL is one of the allowed parameters
          let foundAllowedParam = false;
          for (const param of allowedParams) {
            if (urlParams.has(param)) {
              foundAllowedParam = true;
            }
          }

          if (!foundAllowedParam && newURL) {
            window.location.href = newURL;
            return;
          }
        }

        navigate(0);
      } else {
        setOrg(org);
      }
    },
    [organization, appLoading, navigate, setOrg],
  );

  const monitorUser = useMemo(() => {
    return !excludeUsers.includes(user?.email ?? '');
  }, [user]);

  /**
   * Retrieves the organization to be set for the session based on the specified rules.
   *
   * @param orgs - The list of organizations.
   * @returns The organization to be set for the session, or `null` if no organization is found.
   */
  const orgForSession = useMemo(
    () =>
      ({ orgs }: { orgs: IOrganization[] }): IOrganization | null => {
        const email = auth.currentUser?.email ?? '';
        const env = import.meta.env.VITE_API_URL;
        const lastOrgKey = `${STORAGE_KEYS.lastOrg}-${env}-${email}`;

        const orgIdFromSessionStorage = window.sessionStorage.getItem(SESSION_KEYS.currentOrg);
        const orgIdFromLocalStorage = window.localStorage.getItem(lastOrgKey);

        let currentOrg: IOrganization | undefined;

        /**
         * Org selection priorities:
         * 1. use `orgIdSearchParam` -> org specified by the `orgId` search param (if user belongs to that org)
         * 2. use `orgIdFromSessionStorage` -> org from the session (e.g. on page page refresh)
         * 3. use `orgIdFromLocalStorage` -> most recently used org based on local storage
         * 4. first org from the list
         */

        // 1. org specified by the `orgId` search param
        if (orgIdSearchParam) {
          currentOrg = orgs.find((o) => o.id === orgIdSearchParam);

          if (currentOrg && orgIdFromSessionStorage !== currentOrg.id) {
            // Render a message only if the `orgId` specified in the URL is different from the one in session storage
            message.success(`Switched to ${currentOrg.name}`);
          } else if (currentOrg === undefined) {
            navigate('/404', { replace: true });
            return null;
          }
        }

        // 2. org from the session (e.g. on page refresh)
        if (!currentOrg && orgIdFromSessionStorage) {
          currentOrg = orgs.find((o) => o.id === orgIdFromSessionStorage);
        }

        // 3. most recently used org based on local storage
        if (!currentOrg && orgIdFromLocalStorage) {
          currentOrg = orgs.find((o) => o.id === orgIdFromLocalStorage);
        }

        // 4. first org from the list
        if (!currentOrg) {
          currentOrg = orgs[0];
        }

        return currentOrg ?? null; // `null` if no org found
      },
    [orgIdSearchParam, navigate],
  );

  useEffect(() => {
    if (userLoading) {
      return;
    }

    // if no `user` found, redirect to login
    if (!user) {
      if (PUBLIC_ROUTES.indexOf(location.pathname) === -1) {
        // If we ever have routes that don't require a user we'll move this out and rely on private / protected route wrappers
        navigate('/');
      }
      setAppLoading(false);
      return;
    }

    if (monitorUser && import.meta.env.VITE_APP_BUILD_TYPE !== 'development') {
      LogRocket.init('kalfl0/integral', {
        network: {
          requestSanitizer: (request) => {
            if (request.headers.Authorization) {
              request.headers.Authorization = '<removed>';
            }

            if (
              request.url.includes('identitytoolkit.googleapis.com') &&
              request.body?.includes('password')
            ) {
              request.body = '<removed>';
            }

            return request;
          },
        },
      });
    }

    if (isGetOrganizationsSuccess) {
      // If there are no `orgs` log the user out
      if (!organizations?.length) {
        logout();
        message.error('Sorry, you are not part of the closed beta.');
        return;
      }

      // Set the `org` for the session
      setOrganization(orgForSession({ orgs: organizations }));
      setAppLoading(false);
    } else if (isGetOrganizationsError) {
      console.error(errorForGetOrganizations);
      logout();
      message.error('Something went wrong');
      setAppLoading(false);
    }
  }, [
    userLoading,
    user,
    location.pathname,
    navigate,
    monitorUser,
    orgForSession,
    setOrganization,
    organizations,
    isGetOrganizationsLoading,
    isGetOrganizationsSuccess,
    isGetOrganizationsError,
    errorForGetOrganizations,
    appLoading,
  ]);

  /**
   * Add the `orgId` search param
   */
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isOrgIdSearchParamFirst =
      urlSearchParams.keys().next().value === GLOBAL_SEARCH_PARAMS.orgId.key;

    if (organization && !isOrgIdSearchParamFirst) {
      // reload via setSearchParams and store current location state
      // setLocationState(location.state);
      const newSearchParams = new URLSearchParams();

      // Before setting the existing search parameters, set the `orgId` on the URLSearchParams object first
      newSearchParams.set(GLOBAL_SEARCH_PARAMS.orgId.key, organization.id);
      // Iterate through the current search parameters and append them to the new URLSearchParams object
      searchParams.forEach((value, key) => {
        // Skip `orgId` search params to avoid duplication
        if (key !== GLOBAL_SEARCH_PARAMS.orgId.key) {
          newSearchParams.append(key, value);
        }
      });

      /**
       * Manually call `window.history.pushState` to update the URL
       * NOTE: Avoid using `setSearchParams` since it can interupt the moment when <Navigate> attempts to use a different route due to a race condition
       */
      if (searchParams !== newSearchParams) {
        const newUrl = [window.location.pathname, newSearchParams.toString()]
          .filter(Boolean)
          .join('?');
        window.history.replaceState(null, '', newUrl);
      }
    } else if (locationState) {
      location.state = locationState;
    }
  }, [organization, searchParams, locationState, location]);

  useEffect(() => {
    // If no `user`, clear the organization
    if (!user) {
      setOrganization(null);
      return;
    }

    Sentry.setUser({ email: user.email ?? '', username: user.displayName ?? '' });

    if (monitorUser && import.meta.env.VITE_APP_BUILD_TYPE !== 'development') {
      LogRocket.identify(user.uid, {
        name: user.displayName ?? '',
        email: user.email ?? '',
        subscriptionType: 'demo',
      });

      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }

    getUser()
      .then(({ body }) => {
        const integralUser: IUser = body.data;
        analyticsTracking.identify(
          user.uid,
          user.email ?? '',
          integralUser.type,
          integralUser.accountingFirm,
        );
      })
      .catch((error) => {
        console.error('Error fetching user metadata', error);
        analyticsTracking.identify(user.uid, user.email ?? '');
      });
  }, [user, monitorUser, setOrganization]);

  return { appLoading, user, organizations, organization, setOrganization };
};

export default useAppLoad;
