// React
import React, { useState } from 'react';

// Antd
import { Button } from 'antd';

// Icons
import { Sun, Moon } from '@carbon/icons-react';

// Misc.
import {
  disable as darkreaderDisable,
  enable as darkreaderEnable,
  setFetchMethod,
} from '@umijs/ssr-darkreader';

// Fix darkreader style override bugs
if (typeof window !== 'undefined' && window.fetch) {
  setFetchMethod(window.fetch);
}

export const updateTheme = async (dark: boolean) => {
  if (typeof window === 'undefined') return;
  if (typeof window.MutationObserver === 'undefined') return;

  /*
   * Remove transitions from elements
   */
  const style = document.createElement('style');
  style.textContent = `* { transition: none !important; }`;
  document.body.appendChild(style);

  if (dark) {
    const defaultTheme = {
      brightness: 100,
      contrast: 95,
      sepia: 0,
    };

    const defaultFixes = {
      invert: [],
      css: `
      .ant-modal-root .ant-modal-mask {
        background-color: rgba(13, 13, 13, 0.7);
      }
      `,
      ignoreInlineStyle: ['.react-switch-handle'],
      ignoreImageAnalysis: [],
      disableStyleSheetsProxy: true,
    };

    if (window.MutationObserver) {
      document.documentElement.style.colorScheme = 'dark';
      darkreaderEnable(defaultTheme, defaultFixes);
    }
  } else {
    if (window.MutationObserver) {
      document.documentElement.style.colorScheme = 'dark';
      darkreaderDisable();
    }
  }

  if (window.localStorage) {
    if (dark) {
      // Update localStorage value
      window.localStorage.setItem('integral__theme', 'dark');
    } else {
      window.localStorage.removeItem('integral__theme');
    }
  }

  /*
   * Remove style that removes transitions from elements
   */
  setTimeout(() => {
    if (style && style.parentNode) {
      style.parentNode.removeChild(style);
    }
  }, 500);
};

const DarkModeSwitch: React.FC = () => {
  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem('integral__theme') || 'light',
  );

  return (
    <Button.Group style={{ width: '100%' }}>
      <Button
        block
        icon={<Sun />}
        onClick={(event) => {
          updateTheme(false);
          setCurrentTheme('light');
        }}
        className={currentTheme === 'light' ? 'ant-btn--active' : undefined}
      >
        Light
      </Button>

      <Button
        block
        icon={<Moon />}
        onClick={(event) => {
          updateTheme(true);
          setCurrentTheme('dark');
        }}
        className={currentTheme === 'dark' ? 'ant-btn--active' : undefined}
      >
        Dark
      </Button>
    </Button.Group>
  );
};

export default DarkModeSwitch;
