// Styles
import { PropsWithChildren } from 'react';
import styles from './__styles/PageLayout.module.scss';

// Misc
import classNames from 'classnames';

interface PageLayoutProps {
  className?: string;
  hasBottomPadding?: boolean;
}

export default function PageLayout({
  className,
  hasBottomPadding = true,
  children,
}: PropsWithChildren<PageLayoutProps>) {
  return (
    <div
      className={classNames(className, {
        [styles['page-layout--has-bottom-padding']]: hasBottomPadding,
      })}
    >
      {children}
    </div>
  );
}
