import { AccountingSyncValidationErrorCode } from './accounting.types';

export enum AccountingIntegrationStatus {
  Connected = 'Connected',
  Errored = 'Errored',
  Expired = 'Expired',
}

export interface AccountingIntegrationConnectionDto {
  id: string;
  createdAt: string;
  credentialRefreshedAt: string;
  companyName: string;
  companyId: string;
  lastError?: string;
  lastErrorAt?: string;
  status: AccountingIntegrationStatus;
  accountingDataSyncedAt?: string;
  type: AccountingIntegration;
}

// BE refers to this as `SyncState`
export enum JournalEntrySyncState {
  pending = 'pending',
  creating = 'creating',
  syncing = 'syncing',
  cancelled = 'cancelled',
  finished = 'finished',
  errored = 'errored', // this state doesn't exist in BE -> FE uses this when `SyncStatusResponseDto?.state` is `undefiend` or `null`
}

export interface SyncStatusResponseDto {
  id: string;
  totalTxnCount: number; // number of txns included in this sync
  entryCount: number; // number of journal entries that have been processed/created -- TODO: rename this variable to `processedEntryCount`
  successCount: number; // number of journal entries that successfully synced
  failCount: number; // number of journal entries that failed to sync
  unknownCount: number; // number of journal entries that the sync status was unknown
  state: JournalEntrySyncState; // e.g. "syncing"
  cancelled: boolean;
  finishedAt?: string; // date string
  progress: number | null; // progress out of 100 (e.g. 80)
  estimatedCompletion: number | null; // estimated time to completion in seconds (e.g. 80)
  exportOnly: boolean;
}

export enum AccountingIntegration {
  Quickbooks = 'Quickbooks',
  Xero = 'Xero',
}

export interface StartAccountingSyncOptionsDto {
  // Set to `true` if the journal entries will be only exported instead of pushed to an accounting software.
  exportOnly?: boolean;
  // Use if `exportOnly` is `true`. If true, transactions included in the journal entry export will be marked as synced.
  markTxnsSyncedAfterExport?: boolean;
  // Set to `true` if validation warnings should be ignored. Validation errors cannot be ignored.
  // i.e. ignore warnings of type AccountingSyncValidationError['isWarning'] that are `true`
  ignoreWarnings?: boolean;
}

export interface StartAccountingSyncRequestParamsDto {
  page?: number;
  limit?: number;
  filtersQueryString?: string;
}

export interface StartAccountingSyncRequestData {
  dto?: StartAccountingSyncOptionsDto;
  params?: StartAccountingSyncRequestParamsDto;
}

export interface AccountingSyncValidationError {
  isWarning: boolean;
  code: AccountingSyncValidationErrorCode;
  message: string;
  data?: any; // TODO: define `AccountingSyncValidationErrorData`
}

export interface AccountingSyncConflictResponse {
  statusCode: number;
  code: string; // e.g. "resource_conflict"
  message: string; // e.g. "Validation failed"
  param: AccountingSyncValidationError[];
}
