import React, { useEffect, useState } from 'react';

// Internal
import Typography from '../../components/Typography';

// Firebase
import {
  getAuth,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
} from 'firebase/auth';
import { auth } from '../../services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

// Antd
import { Form, Button, Space, message } from 'antd';

// Styles
import styles from './login.module.scss';

// MISC
import CodeInput from 'react-code-input';
import { get } from 'lodash-es';

let verificationID: any = null;

export enum LoginFormTypes {
  SIGN_IN_FROM_SCRATCH = 'SIGN_IN_FROM_SCRATCH',
  RE_SIGN_IN = 'RE_SIGN_IN',
}
interface MfaVerificationProps {
  mfaStep: number;
  setMfaStep: Function;
  resolver: MultiFactorResolver;
  onSuccessfulLogin?: Function;
}
export const MfaVerification = ({
  mfaStep,
  setMfaStep,
  resolver,
  onSuccessfulLogin,
}: MfaVerificationProps) => {
  useEffect(() => {
    if (mfaStep === 1) {
      (window as any).recaptchaVerifier = new RecaptchaVerifier(
        'mfa-send-code',
        {
          size: 'invisible',
          callback: (response: any) => {
            const phoneInfoOptions = {
              //   phoneNumber: '+11231231234',
              multiFactorHint: resolver.hints[0],
              session: resolver.session,
            };

            const provider = new PhoneAuthProvider(getAuth());

            return provider
              .verifyPhoneNumber(phoneInfoOptions, (window as any).recaptchaVerifier)
              .then((verificationId) => {
                verificationID = verificationId;

                setMfaStep(2);
              })
              .catch((e) => {
                console.log('Verification Error', e);

                if (e.code === 'auth/too-many-requests') {
                  message.error('Too many tries, please try again in 5 minutes');

                  return;
                }
              });
          },
        },
        getAuth(),
      );

      // @ts-ignore
      window.recaptchaVerifier.render();
    }
  }, [mfaStep]);

  const [codeInputValue, setCodeInputValue] = useState<string>('');
  const verifyCode = () => {
    // Ask user for the SMS verification code.
    const cred = PhoneAuthProvider.credential(verificationID, codeInputValue);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    return resolver
      .resolveSignIn(multiFactorAssertion)
      .then((userCredential: any) => {
        if (onSuccessfulLogin) {
          onSuccessfulLogin();
        } else {
          message.success('Welcome back!');
        }
      })
      .catch((e) => {
        if (e.code === 'auth/invalid-verification-code') {
          message.error('Entered code is invalid');
        } else if (e.code === 'auth/code-expired') {
          message.error('Session expired, page will reload');

          setTimeout(() => window.location.reload(), 1000);
        } else {
          message.error('Something went wrong, page will reload');

          setTimeout(() => window.location.reload(), 1000);
        }
      });
  };

  const [user] = useAuthState(auth);
  const [reSignInForm] = Form.useForm();
  if (user) {
    reSignInForm.setFieldsValue({
      email: user.email,
    });
  }

  if (mfaStep === 1) {
    return (
      <>
        <Space direction="vertical" className={styles.loginPage__container__form__methods}>
          <Typography.Title level={1}>2-Factor Authentication</Typography.Title>

          <Typography.Text color="tertiary">
            We will now send a 6-digit code to your added phone number (
            {get(resolver, 'hints.0.phoneNumber')})
          </Typography.Text>

          <Button id="mfa-send-code" type="primary" htmlType="submit">
            Send Code
          </Button>
        </Space>
      </>
    );
  } else {
    return (
      <>
        <Space direction="vertical" className={styles.loginPage__container__form__methods}>
          <Typography.Title level={1}>2-Factor Authentication</Typography.Title>

          <Typography.Text color="tertiary">
            Please enter the 6-digit code we've sent you ({get(resolver, 'hints.0.phoneNumber')})
          </Typography.Text>

          <CodeInput
            name="verification"
            inputMode="numeric"
            type="number"
            fields={6}
            onChange={(value) => setCodeInputValue(value)}
          />

          <Button
            id="mfa-signin"
            type="primary"
            htmlType="submit"
            onClick={verifyCode}
            disabled={isNaN(codeInputValue as any) || codeInputValue.length !== 6}
            style={{ marginTop: '1rem' }}
          >
            Submit
          </Button>
        </Space>
      </>
    );
  }
};
