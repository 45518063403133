import React from 'react';

import { Result } from 'antd';
import Typography from './Typography';

// Styles
import './EmptyState.scss';

interface EmptyStateProps {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  action?: React.ReactNode;
  icon?: React.ReactNode;
}

// antd
export default function EmptyState({ action, icon, subTitle, title }: EmptyStateProps) {
  return (
    <div className="empty-state">
      <Result
        icon={icon}
        title={<Typography.Title level={3}>{title}</Typography.Title>}
        subTitle={
          <Typography.Text
            style={{ maxWidth: 350, margin: 'auto', marginTop: '0.5rem' }}
            color="tertiary"
          >
            {subTitle}
          </Typography.Text>
        }
        extra={[action]}
      />
    </div>
  );
}
