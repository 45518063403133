// Internal
import {
  CreateOrganizationDto,
  IOrganization,
  OrgQuotaDetails,
  OrganizationMember,
  OrganizationMetadataDto,
  QuotaName,
  UpdateOrganizationDto,
  UpdateOrganizationMemberDTO,
  UpdateOrganizationSettingsDto,
  IOrganizationSettings,
  OrgContract,
} from './__types/organization.types';
import {
  ApiResponse,
  deleteRequest,
  getRequest,
  patchRequest,
  postMultipartFormRequest,
  postRequest,
} from './request';
import { CreateInviteDto } from './invite';
import { AnalyticsEventName, analyticsTracking } from './analytics';

// Misc
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { keyBy } from 'lodash-es';

// Export types
export * from './__types/organization.types';

// Query keys
export const organizationQueryKeys = {
  all: ['organizations'] as const,
  orgs: () => [...organizationQueryKeys.all, 'orgs'] as const,
  org: (orgId: string) => [...organizationQueryKeys.orgs(), orgId] as const,
  orgMembers: (orgId: string) => [...organizationQueryKeys.org(orgId), 'orgMembers'] as const,
  orgMember: (orgId: string) => [...organizationQueryKeys.org(orgId), 'orgMember'] as const,
  roles: (orgId: string) => [...organizationQueryKeys.org(orgId), 'roles'] as const,
  quota: (orgId: string) => [...organizationQueryKeys.org(orgId), 'quota'] as const,
  txnLabelsForOrg: (orgId: string) => [...organizationQueryKeys.org(orgId), 'txnLabels'] as const,
  orgSettings: (orgId: string) => [...organizationQueryKeys.org(orgId), 'settings'] as const,
  orgContract: () => [...organizationQueryKeys.all, 'orgContract'] as const,
};

export const getOrganizations = () =>
  getRequest<ApiResponse<IOrganization[]>>('/organizations', {});
export const useGetOrganizations = ({ enabled = true }: { enabled?: boolean } = {}) => {
  return useQuery<IOrganization[], Error>({
    enabled: enabled,
    queryKey: organizationQueryKeys.orgs(),
    queryFn: async () => {
      const { body } = await getOrganizations();

      // Sort the orgs by `name`
      if (Array.isArray(body.data)) {
        const orgs = body.data as IOrganization[];
        orgs.sort((a, b) => a.name.localeCompare(b.name));
        return orgs;
      } else {
        return body.data;
      }
    },
  });
};

const _getTxnLabels = (organizationId: string) =>
  getRequest<ApiResponse<IOrganization[]>>(`/organizations/${organizationId}/metadata`, null);
export const useGetTxnLabels = (organizationId: string) => {
  return useQuery<OrganizationMetadataDto, Error>({
    enabled: !!organizationId,
    queryKey: organizationQueryKeys.txnLabelsForOrg(organizationId),
    queryFn: async () => {
      const { body } = await _getTxnLabels(organizationId);
      return body.data;
    },
  });
};

export const updateOrganization = (organizationId: string, dto: UpdateOrganizationDto) =>
  patchRequest<ApiResponse<IOrganization>>(`/organizations/${organizationId}`, dto);
export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, dto }: { id: string; dto: UpdateOrganizationDto }) => {
      const { body } = await updateOrganization(id, dto);
      return body.data;
    },
    onSuccess: () => {
      // TODO: use setQueryData
      queryClient.invalidateQueries(organizationQueryKeys.orgs());
    },
  });
};

export const getOrganizationMember = (organizationId: string) =>
  getRequest<ApiResponse<OrganizationMember>>(`/organizations/${organizationId}/member`, null);

export const getOrganizationMembers = (organizationId: string) =>
  getRequest<ApiResponse<OrganizationMember[]>>(`/organizations/${organizationId}/members`, null);

export const createOrganization = (dto?: CreateOrganizationDto) =>
  postRequest<string>(`/organizations/`, dto);

export const deleteOrganization = (organizationId: string) =>
  deleteRequest<string>(`/organizations/${organizationId}`, null);

export const getOrganizationLogo = (organizationId: string) =>
  getRequest<ApiResponse<string>>(`/organizations/${organizationId}/logo`, null);

export const updateOrganizationLogo = (organizationId: string, formData: FormData) => {
  return postMultipartFormRequest(`/organizations/${organizationId}/logo`, formData);
};

export const deleteOrganizationLogo = (organizationId: string) =>
  deleteRequest<string>(`/organizations/${organizationId}/logo`, null);

export const useOrgMember = (organization: IOrganization) => {
  return useQuery<OrganizationMember, Error>({
    queryKey: organizationQueryKeys.orgMember(organization.id),
    queryFn: async () => {
      const { body } = await getOrganizationMember(organization.id);
      return body.data;
    },
    enabled: !!organization.id,
  });
};

export const useOrgMembers = (organization: IOrganization) => {
  return useQuery<OrganizationMember[], Error>({
    queryKey: organizationQueryKeys.orgMembers(organization.id),
    queryFn: async () => {
      const { body } = await getOrganizationMembers(organization.id);
      return body.data;
    },
    enabled: !!organization.id,
  });
};

const _getRoles = (organizationId: string) =>
  getRequest<ApiResponse<OrganizationMember[]>>(`/organizations/${organizationId}/roles`, null);

export const useGetRoles = (organization: IOrganization) => {
  return useQuery<OrganizationMember[], Error>({
    queryKey: organizationQueryKeys.roles(organization.id),
    queryFn: async () => {
      const { body } = await _getRoles(organization.id);
      return body.data;
    },
    enabled: !!organization.id,
  });
};

export const updateOrgMemberRole = (
  organizationId: string,
  memberId: string,
  dto: UpdateOrganizationMemberDTO,
) => {
  return patchRequest(`/organizations/${organizationId}/members/${memberId}`, dto);
};

export const useUpdateOrgMemberRole = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      id,
      memberId,
      dto,
    }: {
      id: string;
      memberId: string;
      dto: UpdateOrganizationMemberDTO;
    }) => {
      const { body } = await updateOrgMemberRole(id, memberId, dto);
      return body.data;
    },
    onSuccess: (data, { id, memberId }) => {
      // TODO: use setQueryData

      queryClient.setQueryData(organizationQueryKeys.orgMembers(id), (oldData: any) => {
        return oldData.map((member: any) => {
          if (member.id === memberId) {
            return data;
          }
          return member;
        });
      });
    },
  });
};

export const removeOrgMemberRole = (organizationId: string, memberId: string) => {
  return deleteRequest(`/organizations/${organizationId}/members/${memberId}`);
};

export const useRemoveOrgMemberRole = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, memberId }: { id: string; memberId: string }) => {
      const { body } = await removeOrgMemberRole(id, memberId);
      return body.data;
    },
    onSuccess: (_, { id, memberId }) => {
      queryClient.setQueryData(organizationQueryKeys.orgMembers(id), (oldData: any) => {
        return oldData.filter((member: any) => member.id !== memberId);
      });
    },
  });
};

export const createInvite = (dto: CreateInviteDto) => postRequest(`/invites/`, dto);
export const useCreateInvite = (organization: IOrganization) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ dto }: { dto: CreateInviteDto }) => {
      const { body } = await createInvite(dto);
      return body.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(organizationQueryKeys.orgMembers(organization.id));
    },
  });
};

interface QuotaData {
  byId: Record<QuotaName, OrgQuotaDetails>;
  quotas: OrgQuotaDetails[];
}

const _getQuotas = (organizationId: string) =>
  getRequest<ApiResponse<OrgQuotaDetails[]>>(`/organizations/${organizationId}/quotas`, null);
export const useGetQuotas = (organizationId: string) => {
  const query = useQuery<QuotaData, Error>({
    queryKey: organizationQueryKeys.quota(organizationId),
    queryFn: async () => {
      const { body } = await _getQuotas(organizationId);
      const byId = keyBy(body.data, 'name') as Record<QuotaName, OrgQuotaDetails>;
      return { quotas: body.data, byId };
    },
  });

  return {
    query,
    data: query.data?.quotas as OrgQuotaDetails[],
    byId: query.data?.byId as Record<QuotaName, OrgQuotaDetails>,
    loading: query.isLoading,
  };
};

const _getOrganizationSettings = (orgId: string) =>
  getRequest<ApiResponse<IOrganizationSettings>>(`/organization/${orgId}/settings`, null);

export const useGetOrganizationSettings = (orgId: string) => {
  return useQuery<IOrganizationSettings, Error>({
    queryKey: organizationQueryKeys.orgSettings(orgId),
    queryFn: async () => {
      const { body } = await _getOrganizationSettings(orgId);
      return body.data;
    },
  });
};

const _updateOrganizationSettings = (orgId: string, dto: UpdateOrganizationSettingsDto) =>
  patchRequest<ApiResponse<IOrganizationSettings>>(`/organization/${orgId}/settings`, dto);

export const useUpdateOrganizationSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: { id: string; dto: UpdateOrganizationSettingsDto }) => {
      const { id, dto } = params;
      const { body } = await _updateOrganizationSettings(id, dto);
      return body.data;
    },
    onSuccess: (orgSettings: IOrganizationSettings, { id, dto }) => {
      const { defaultToBkv2 } = dto;
      queryClient.setQueryData(organizationQueryKeys.orgSettings(id), { ...orgSettings });

      if (defaultToBkv2) {
        analyticsTracking.track(AnalyticsEventName.Bkv2OrgSwitch);
      }
    },
  });
};

export const getOrgContract = () => getRequest<ApiResponse<OrgContract>>('/org-contract', {});

export const useGetOrgContract = () => {
  return useQuery<OrgContract, Error>({
    queryKey: organizationQueryKeys.orgContract(),
    queryFn: async () => {
      const { body } = await getOrgContract();

      return {
        ...body.data,
        tiers: ((body.data.tiers || []) as OrgContract['tiers']).map((tier) => ({
          ...tier,
          preferredPrice: tier.preferredPrice === 0 ? null : tier.preferredPrice,
        })),
      };
    },
    onError: (error) => {
      // Handle the error locally without triggering the default onError
      console.log('Error fetching org contract:', error);
    },
    retry: false,
  });
};
