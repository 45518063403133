import { ReactNode, createContext, useReducer } from 'react';
// hooks

import {
  ActionMap,
  AccountName,
  BalanceCheck,
  getAccountNames,
  getBalanceChecks,
  BalanceCheckSnapshotGroup,
  getBalanceCheckSnapshotGroups,
} from '@/api/accounts';

import { message } from 'antd';
import { getAssetList, UniqAsset } from '@/api/tokens';

export type WalletsAssetsContextType = {
  isLoading: boolean;
  assetsList: UniqAsset[];
  accountNames: AccountName[];
  balanceChecks: BalanceCheck[];
  balanceCheckSnapshotGroups: BalanceCheckSnapshotGroup[];
  onGetTokensIds: Function;
  onGetAccountsNames: Function;
  onGetBalanceChecks: Function;
  onGetBalanceCheckSnapshotGroups: Function;
};

export type WalletsAssetsContextProps = {
  isLoading: boolean;
  assetsList: UniqAsset[];
  accountNames: AccountName[];
};

const initialState: WalletsAssetsContextProps = {
  isLoading: false,
  assetsList: [],
  accountNames: [],
};

enum Types {
  startLoading = 'startLoading',
  assetIds = 'assetIds',
  accountNames = 'accountNames',
  balanceChecks = 'balanceChecks',
  balanceCheckSnapshotGroups = 'balanceCheckSnapshotGroups',
}

type WalletsAssetsPayload = {
  [Types.startLoading]: {
    isLoading: boolean;
  };
  [Types.accountNames]: {
    accountNames: AccountName[];
    isLoading: boolean;
  };
  [Types.assetIds]: {
    assetsList: UniqAsset[];
    isLoading: boolean;
  };
  [Types.balanceChecks]: {
    balanceChecks: BalanceCheck[];
    isLoading: boolean;
  };
  [Types.balanceCheckSnapshotGroups]: {
    balanceCheckSnapshotGroups: BalanceCheckSnapshotGroup[];
    isLoading: boolean;
  };
};

type WalletsAssetsActions = ActionMap<WalletsAssetsPayload>[keyof ActionMap<WalletsAssetsPayload>];

const WalletsAssetsContext = createContext<WalletsAssetsContextType | null>(null);

const reducer = (state: WalletsAssetsContextProps, action: WalletsAssetsActions) => {
  const { isLoading } = action.payload as WalletsAssetsContextProps;
  switch (action.type) {
    case Types.startLoading:
      return {
        ...state,
        isLoading,
      };
    case Types.accountNames:
      const { accountNames } = action.payload;
      return {
        ...state,
        accountNames,
        isLoading,
      };
    case Types.assetIds:
      const { assetsList } = action.payload;
      return {
        ...state,
        assetsList,
        isLoading,
      };
    case Types.balanceChecks:
      const { balanceChecks } = action.payload;
      return {
        ...state,
        balanceChecks,
        isLoading,
      };
    case Types.balanceCheckSnapshotGroups:
      const { balanceCheckSnapshotGroups } = action.payload;
      return {
        ...state,
        balanceCheckSnapshotGroups,
        isLoading,
      };

    default:
      return state;
  }
};

type WalletsAssetsReportProps = {
  children: ReactNode;
};

function WalletsAssetsProvider({ children }: WalletsAssetsReportProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const onGetAccountsNames = () => {
    dispatch({
      type: Types.startLoading,
      payload: { isLoading: true },
    });
    getAccountNames()
      .then(({ body }) =>
        dispatch({
          type: Types.accountNames,
          payload: {
            accountNames: body.data || null,
            isLoading: false,
          },
        }),
      )
      .catch((err) => console.log({ err }));
  };

  const onGetTokensIds = (spam?: { isSpam: boolean }) => {
    dispatch({
      type: Types.startLoading,
      payload: { isLoading: true },
    });

    getAssetList(spam)
      .then(({ body }) => {
        dispatch({
          type: Types.assetIds,
          payload: {
            assetsList: body.data || null,
            isLoading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Something went wrong');
      });
  };

  const onGetBalanceChecks = (snapshotGroupId?: string) => {
    dispatch({
      type: Types.startLoading,
      payload: { isLoading: true },
    });
    getBalanceChecks(snapshotGroupId)
      .then(({ body }) => {
        dispatch({
          type: Types.balanceChecks,
          payload: {
            balanceChecks: body.data || null,
            isLoading: false,
          },
        });
      })
      .catch((err) => console.log({ err }));
  };

  const onGetBalanceCheckSnapshotGroups = () => {
    dispatch({
      type: Types.startLoading,
      payload: { isLoading: true },
    });

    getBalanceCheckSnapshotGroups().then(({ body }) => {
      dispatch({
        type: Types.balanceCheckSnapshotGroups,
        payload: {
          balanceCheckSnapshotGroups: body.data || [],
          isLoading: false,
        },
      });
    });
  };

  return (
    <WalletsAssetsContext.Provider
      value={{
        ...state,
        onGetAccountsNames,
        onGetTokensIds,
        onGetBalanceChecks,
        onGetBalanceCheckSnapshotGroups,
      }}
    >
      {children}
    </WalletsAssetsContext.Provider>
  );
}

export { WalletsAssetsProvider, WalletsAssetsContext };
