import React, { useCallback, useState } from 'react';

// Internal
import { IOrganization, getOrganizationLogo, getOrganizations } from '@/api/organization';

type OrganizationsProviderProps = {
  children: React.ReactNode;
  organizations: IOrganization[];
  organization: IOrganization;
  setOrganization: Function;
};

const OrganizationsStateContext = React.createContext<
  | {
      // Current ORG
      organization: IOrganization;
      setOrganization: Function;
      // ORGS
      organizations: IOrganization[] | null;
      organizationsLoading: boolean;
      reFetchOrganizations: Function;
      // LOGO
      organizationLogoURL: string | null;
      logoLoading: boolean;
      reFetchOrganizationLogo: Function;
    }
  | undefined
>(undefined);

function OrganizationsProvider({
  children,
  organization,
  organizations: orgs,
  setOrganization,
}: OrganizationsProviderProps) {
  const [organizations, setOrganizations] = React.useState<IOrganization[] | null>(orgs);
  const [organizationsLoading, setOrganizationsLoading] = React.useState(false);

  const [logoLoading, setLogoLoading] = React.useState(true);
  const [organizationLogoURL, setOrganizationLogoURL] = useState<string | null>(null);

  const fetchOrganizations = useCallback(() => {
    setOrganizationsLoading(true);

    getOrganizations()
      .then(({ body }) => {
        setOrganizations(body.data);
      })
      .catch((err) => {
        console.error('Error fetching organizations', err.message);
      })
      .finally(() => {
        setOrganizationsLoading(false);
      });
  }, []);

  const fetchOrganizationLogo = useCallback(() => {
    setLogoLoading(true);

    getOrganizationLogo(organization.id)
      .then(({ body }) => {
        setOrganizationLogoURL(URL.createObjectURL(body));
      })
      .catch((err) => {
        if (err.statusCode === 404) {
          setOrganizationLogoURL(null);
        }

        console.error('Error fetching organization logo', err);
      })
      .finally(() => {
        setLogoLoading(false);
      });
  }, [organization]);

  React.useEffect(() => {
    fetchOrganizationLogo();
  }, []);

  const value = {
    // Current ORG
    organization,
    setOrganization,
    // ORGS
    organizations,
    organizationsLoading: organizationsLoading,
    reFetchOrganizations: fetchOrganizations,
    // LOGO
    organizationLogoURL,
    logoLoading,
    reFetchOrganizationLogo: fetchOrganizationLogo,
  };

  return (
    <OrganizationsStateContext.Provider value={value}>
      {children}
    </OrganizationsStateContext.Provider>
  );
}

function useOrganizations() {
  const context = React.useContext(OrganizationsStateContext);

  if (context === undefined) {
    throw new Error('useOrganizations must be used within a OrganizationsProvider');
  }

  return context;
}

export { OrganizationsProvider, useOrganizations };
