// Styles
import { SASS_VARIABLES } from '@/utils/styles';

// Antd
import { ThemeConfig } from 'antd';

const tabsConfig: NonNullable<ThemeConfig['components']>['Tabs'] = {
  itemColor: String(SASS_VARIABLES.colorTextTertiary),
  horizontalItemPadding: '0 0 12px 0',
  horizontalItemGutter: 24,
};

export default tabsConfig;
