// react-query
import { useQuery } from '@tanstack/react-query';

// Internal
import {
  AccountingIntegration,
  AccountingIntegrationConnectionDto,
  StartAccountingSyncRequestData,
  StartAccountingSyncRequestParamsDto,
  SyncStatusResponseDto,
} from './accounting-integrations';
import { AnalyticsEventName, analyticsTracking } from './analytics';
import { deleteRequest, getRequest, postRequest } from './request';

// Misc
import { first, get } from 'lodash-es';

// export types
export * from './__types/accounting-integrations.types';

const accountingQueryKeys = {
  all: ['all'] as const,
  connections: () => [accountingQueryKeys.all, 'accountingConnections'] as const,
};

export async function startAccountingConnection(integration: AccountingIntegration) {
  const startResult = await postRequest('/accounting-integrations/start-connection', {
    integration,
  });
  // Use the current location as a redirect_uri to make it work in deploy preview and different domains.
  const authUri = new URL(startResult.body.data.authUri);
  authUri.searchParams.set(
    'redirect_uri',
    `${window.location.protocol}//${
      window.location.host
    }/settings/${integration.toLowerCase()}/redirect`,
  );
  return authUri.toString();
}

export function finishAccountingConnection(url: string) {
  return postRequest('/accounting-integrations/finish-connection', { url });
}

export async function getAccountingConnections(): Promise<AccountingIntegrationConnectionDto[]> {
  const result = await getRequest('/accounting-integrations', {});
  const connections = result.body.data;
  return connections;
}

export const useGetAccountingConnections = () => {
  return useQuery<AccountingIntegrationConnectionDto[], Error>({
    queryKey: accountingQueryKeys.connections(),
    queryFn: async () => {
      return await getAccountingConnections();
    },
  });
};

export async function getConnection() {
  return first(await getAccountingConnections());
}

export const deleteAccountingConnection = (connectionId: string) =>
  deleteRequest(`/accounting-integrations/${connectionId}`, null);

export const syncAccountingConnection = () => postRequest('/accounting-integrations/sync', {});

export const startAccountingSync = async ({ dto, params }: StartAccountingSyncRequestData) => {
  const requestURL = `/accounting-integrations/start-accounting-sync${params ? `?${_generateStartAccountingSyncRequestParams(params).toString()}` : ''}`;
  const result = await postRequest<SyncStatusResponseDto>(requestURL, dto ?? {});

  if (result.res.ok) {
    analyticsTracking.track(AnalyticsEventName.JournalSync, {
      exportOnly: dto?.exportOnly ?? false,
    });
  }
  return result.body.data;
};

export const stopAccountingSync = () =>
  postRequest('/accounting-integrations/stop-accounting-sync', {});

export const getAccountingSyncStatus = async () => {
  const result = await getRequest<SyncStatusResponseDto>(
    '/accounting-integrations/sync-status',
    {},
  );

  // NOTE: BE sometimes returns `{ "data": null }` with a 200 status code. This happens when the workflow is highly constrained).
  // Querying the workflow status too often strains the workflow (as the query is executed on the workflow itself).
  return result.body.data as SyncStatusResponseDto | null;
};

// Generates a URLSearchParams object from the dto
const _generateStartAccountingSyncRequestParams = (dto: StartAccountingSyncRequestParamsDto) => {
  const params = new URLSearchParams(dto?.filtersQueryString || '');
  ['limit', 'page'].forEach((key) => {
    const _value = get(dto, key);
    if (_value !== undefined && _value !== null) {
      params.append(key, `${_value}`);
    }
  });

  return params;
};
