// React
import { ElementType, Suspense, lazy, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, useRoutes } from 'react-router-dom';

// Sentry
import * as Sentry from '@sentry/react';

// Internal
import { PermissionsList } from '@/api/__types/roles.types';
import { ComingSoonSection } from '@/components/ComingSoonSection';
import FullPageSplash from '@/components/FullPageSplash';
import withPermission from '@/components/HOCs/withPermission';
import { RBAC_MAP } from '@/types/rbac-map';
import { useEnabledFeatures } from '@/contexts/EnabledFeatures';

// Antd
import { Spin } from 'antd';

// Assets
import ERROR_IMAGE from '../img/pages/404/image.svg';

// Styles
import styles from '../App.module.scss';

export enum APP_ROUTES {
  Bills = 'bills',
  Approvals = 'approvals',
  Bookkeeping = 'accounting',
  BookkeepingV2 = 'bookkeeping',
  Contacts = 'contacts',
}

const CHUNK_EXPIRY_KEY = 'chunk_error'; // local storage key
const CHUNK_EXPIRY_TIME = 10000; // if error happens within 10 seconds of last one, don't reload to prevent infinite loop

function setChunkExpiry() {
  const item = {
    expiresAt: new Date().getTime() + CHUNK_EXPIRY_TIME,
  };

  window.localStorage.setItem(CHUNK_EXPIRY_KEY, JSON.stringify(item));
}

function shouldReloadChunk() {
  const itemString = window.localStorage.getItem(CHUNK_EXPIRY_KEY);
  if (!itemString) return true;

  const item: { expiresAt: number } = JSON.parse(itemString);
  const isExpired = new Date().getTime() > item.expiresAt;

  if (isExpired) {
    window.localStorage.removeItem(CHUNK_EXPIRY_KEY);

    return true;
  }

  return false;
}

// Error page for boundary
const ErrorFallback = ({ error }: { error: any }) => {
  const [showErrorPage, setShowErrorPage] = useState<boolean>(false);

  // Handles failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (window.localStorage && shouldReloadChunk()) {
        setChunkExpiry();
        window.location.reload();

        return;
      }
    }

    setShowErrorPage(true);
  }, [error]);

  useEffect(() => {
    if (showErrorPage) {
      Sentry.captureException(error);
    }
  }, [showErrorPage, error]);

  if (!showErrorPage) {
    return null;
  }

  return (
    <FullPageSplash
      actions={null}
      backgroundStyles={{
        backgroundImage: `url(${ERROR_IMAGE})`,
        backgroundColor: '#fff',
        backgroundSize: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      title="Uh oh, something went wrong"
      subTitle="Our apologies, something went wrong. Please try reloading the page to try again."
      pageTitle="Unknown Error"
    />
  );
};

const Loadable =
  <P = any,>(Component: ElementType): React.FC<P> =>
  (props: P) => {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<Spin className={styles.loading} />}>
          <Component {...props} />
        </Suspense>
      </ErrorBoundary>
    );
  };

export default function Router() {
  const { isTreasuryShowPnlTabEnabled } = useEnabledFeatures();

  return useRoutes([
    {
      path: '/',
      element: <DashboardShell />,
      children: [
        {
          element: <Navigate to="/treasury" replace />,
          index: true,
        },
        {
          path: '__internal',
          children: [
            { element: <Navigate to="/components" replace />, index: true },
            {
              path: 'token-previewer',
              element: <TokenPreviewer />,
            },
            { path: 'components', element: <ComponentsPage /> },
            { path: 'relative-time', element: <RelativeTime /> },
          ],
        },
        {
          path: 'treasury',
          element: <Treasury permission={PermissionsList.ReadOnlyPermission.ReadOnly} />,
          children: [
            { element: <Navigate to="/treasury/portfolio" replace />, index: true },
            {
              path: 'portfolio',
              element: <Portfolio />,
            },
            {
              path: 'profit-and-loss',
              element: isTreasuryShowPnlTabEnabled ? (
                <ProfitAndLoss />
              ) : (
                <Navigate to="/treasury/portfolio" replace />
              ),
            },
            { path: 'csv-import-history/:accountId', element: <CSVImportHistory /> },
          ],
        },
        {
          path: 'wallets',
          element: <ConnectedAccounts permission={PermissionsList.ReadOnlyPermission.ReadOnly} />,
        },
        {
          path: 'nft-royalties',
          children: [
            { element: <NftCollections />, index: true },
            { path: ':id', element: <NftCollectionDetails /> },
          ],
        },
        {
          path: APP_ROUTES.Bills,
          children: [
            {
              element: <Bills permission={PermissionsList.ReadOnlyPermission.ReadOnly} />,
              index: true,
            },
            {
              path: ':invoiceId',
              element: <BillDetails permission={PermissionsList.ReadOnlyPermission.ReadOnly} />,
            },
            {
              path: `/${APP_ROUTES.Bills}/create`,
              element: (
                <CreateOrEditBill permission={PermissionsList.PaymentsPermission.CreateBills} />
              ),
            },
            {
              path: `/${APP_ROUTES.Bills}/edit/:invoiceId`,
              element: (
                <CreateOrEditBill permission={PermissionsList.PaymentsPermission.CreateBills} />
              ),
            },
          ],
        },
        {
          path: APP_ROUTES.Approvals,
          element: <BillApprovals permission={PermissionsList.PaymentsPermission.ApproveBills} />,
        },
        {
          path: 'assets',
          element: <Assets />,
          children: [
            {
              element: <Navigate to="/assets/tokens" replace />,
              index: true,
            },
            {
              path: 'tokens',
              element: <AssetsTokensTab />,
            },
            {
              path: 'nft-collections',
              element: <Nft />,
            },
            {
              path: 'defi',
              element: <AssetsDefiTab />,
            },
            {
              path: 'vesting-tokens',
              element: <VestingTokens />,
            },
            { path: 'fiat', element: <AssetsFiatView /> },
          ],
        },
        { path: '/assets/vesting-tokens/:id', element: <VestingContract /> },
        {
          path: APP_ROUTES.Bookkeeping,
          children: [
            {
              element: <AccountingTable permission={PermissionsList.ReadOnlyPermission.ReadOnly} />,
              index: true,
            },
            { path: 'onboard', element: <AccountingOnboard /> },
          ],
        },
        {
          path: APP_ROUTES.BookkeepingV2,
          children: [{ element: <BookkeepingV2 />, index: true }],
        },
        {
          path: '/impairment',
          children: [
            {
              element: <Impairment permission={PermissionsList.ReadOnlyPermission.ReadOnly} />,
              index: true,
            },
            {
              path: 'calculate',
              element: (
                <CalculateImpairment
                  permission={
                    RBAC_MAP.Impairment.CreateImpairmentTest.permission ||
                    RBAC_MAP.Impairment.MainImpairmentTable.ReviewImpairment.permission
                  }
                />
              ),
            },
          ],
        },
        {
          path: 'policy-center',
          element: <Policies />,
          children: [
            { element: <Navigate to="/policy-center/chart-of-accounts" replace />, index: true },
            { path: 'chart-of-accounts', element: <ChartOfAccounts /> },
            {
              path: 'spam',
              element: <PoliciesSpamView />,
            },
            { path: 'accounting-policies', element: <AccountingPolicies /> },
            {
              path: 'impairment-testing',
              element: <ImpairmentTesting headerTitle="Impairment Testing" />,
            },
            {
              path: 'nft-valuation',
              element: <NftValuationPolicies />,
            },
            {
              path: 'defi',
              element: <PoliciesDeFiView />,
            },
            {
              path: 'bill-approval',
              element: <BillApprovalPolicies />,
            },
          ],
        },
        {
          path: 'reports',
          children: [
            {
              element: <Reports permission={PermissionsList.ReadOnlyPermission.ReadOnly} />,
              index: true,
            },

            // Summary Reports
            {
              path: 'requests',
              element: (
                <RequestedReports permission={RBAC_MAP.Reports.RequestedReports.permission} />
              ),
            },
            {
              path: 'balances-report',
              element: <ClosingPositionsReport />,
              children: [
                {
                  element: <Navigate to={`/reports/balances-report/tokens`} replace />,
                  index: true,
                },
                { path: 'tokens', element: <TokensClosingPositionsReport /> },
                { path: 'nft-collections', element: <NftClosingPositionsReport /> },
                { path: 'wallets', element: <WalletsClosingPositionsReport /> },
                {
                  path: 'defi',
                  element: <DefiClosingPositionsReport />,
                },
              ],
            },
            { path: 'gain-loss-report', element: <GainLossReport /> },
            { path: 'profit-loss-report', element: <ProfitLossReport /> },
            { path: 'wallet-token-balances-report', element: <WalletTokenBalancesReport /> },

            // Compliance Reports
            { path: 'fasb-roll-forward-report', element: <FasbRollForwardReport /> },

            // Detailed Reports
            { path: 'account-transactions-report', element: <AccountTransactionsReport /> },
            { path: 'journal-entry-report', element: <JournalEntryReport /> },
            { path: 'schedule-dispositions-report', element: <ScheduleDispositionsReport /> },
            { path: 'cost-basis-report', element: <CostBasisReport /> },
            { path: 'royalty-fees-report', element: <RoyaltyFeesReport /> },
          ],
        },
        {
          path: 'rules',
          element: <Rules permission={PermissionsList.ReadOnlyPermission.ReadOnly} />,
        },
        {
          path: 'tax-lots',
          element: <TaxLots permission={PermissionsList.ReadOnlyPermission.ReadOnly} />,
        },
        { path: 'tax-lots/:id', element: <TaxLot /> },
        {
          path: '/setup',
          children: [
            { element: <Setup />, index: true },
            {
              path: 'introduction-to-bookkeeping',
              element: (
                <IntroductionToBookkeeping
                  permission={RBAC_MAP.GettingStarted.IntroductionToBookkeeping.permission}
                />
              ),
            },
            {
              path: 'import-chart-of-accounts',
              element: (
                <ImportChartOfAccounts
                  permission={RBAC_MAP.GettingStarted.ImportChartOfAccounts.permission}
                />
              ),
            },
            {
              path: 'map-chart-of-accounts',
              element: (
                <MapChartOfAccounts
                  permission={RBAC_MAP.GettingStarted.MapChartOfAccounts.permission}
                />
              ),
            },
          ],
        },
        {
          path: '/settings',
          element: <Settings />,
          children: [
            { element: <Navigate to="/settings/profile" replace />, index: true },
            { path: 'profile', element: <Profile /> },
            { path: 'organization', element: <OrganizationSettings /> },
            {
              path: 'roles-accesses',
              element: <ComingSoonSection headerTitle="Roles & Accesses" />,
            },
            {
              path: 'add-member',
              element: (
                <AddMember permission={PermissionsList.OrganizationsPermission.ManageUsers} />
              ),
            },

            { path: 'team', element: <ComingSoonSection headerTitle="Team" /> },
            { path: 'quickbooks/redirect', element: <AccountingIntegrationRedirect /> },
            { path: 'xero/redirect', element: <AccountingIntegrationRedirect /> },
            { path: 'integrations', element: <Integrations /> },
            { path: 'usage', element: <Usage /> },
          ],
        },
        {
          path: '/settings/edit-member/:id',
          element: (
            <EditMember permission={PermissionsList.OrganizationsPermission.AssignUserRoles} />
          ),
        },
        { path: '/coming-soon', element: <ComingSoon /> },
        {
          path: APP_ROUTES.Contacts,
          element: <Contacts permission={PermissionsList.ReadOnlyPermission.ReadOnly} />,
        },
        { path: `${APP_ROUTES.Contacts}/:id`, element: <Contact /> },
      ],
    },
    {
      path: 'accounting/enrich',
      element: (
        <AccountingEnrich permission={PermissionsList.BookkeepingPermission.EditTransactions} />
      ),
    },
    { path: 'accounting/wallets', element: <AccountingWallets /> },
    {
      path: 'accounts/add/',
      element: <AddAccount permission={PermissionsList.SetupPermission.ManageWallets} />,
    },
    { path: 'accounts/add/:providerId', element: <AddAccount /> },
    { path: 'invite', element: <Invite /> },
    { path: 'reset-password', element: <ResetPassword /> },
    { path: 'create-account', element: <CreateAccount /> },
    { path: '*', element: <Page404 /> },
  ]);
}

// Dashboard
const DashboardShell = withPermission(Loadable(lazy(() => import('@/components/Shell/Shell'))));

// Internal
const TokenPreviewer = withPermission(
  Loadable(lazy(() => import('@/pages/__internal/token-previewer'))),
);
const ComponentsPage = withPermission(
  Loadable(lazy(() => import('@/pages/__internal/components'))),
);
const RelativeTime = withPermission(
  Loadable(lazy(() => import('@/pages/__internal/relative-time'))),
);

// Treasury
const Treasury = withPermission(Loadable(lazy(() => import('@/pages/accounts/treasury'))));
const Portfolio = withPermission(Loadable(lazy(() => import('@/pages/accounts/accounts'))));
const ProfitAndLoss = withPermission(
  Loadable(lazy(() => import('@/pages/accounts/profit-and-loss'))),
);
const CSVImportHistory = withPermission(
  Loadable(lazy(() => import('@/pages/accounts/csv-import-history'))),
);

// NFT Royalties
const NftCollections = withPermission(
  Loadable(lazy(() => import('@/pages/nft-collections/nft-collections'))),
);
const NftCollectionDetails = withPermission(
  Loadable(lazy(() => import('@/pages/nft-collections/nft-collection'))),
);

// Bills
const Bills = withPermission(Loadable(lazy(() => import('@/pages/bills/bills'))));
const BillDetails = withPermission(Loadable(lazy(() => import('@/pages/bills/bill'))));
const CreateOrEditBill = withPermission(Loadable(lazy(() => import('@/pages/bills/create'))));
const BillApprovals = withPermission(Loadable(lazy(() => import('@/pages/bills/approvals'))));

// Assets
const Assets = withPermission(Loadable(lazy(() => import('@/pages/assets/assets'))));
const AssetsTokensTab = Loadable(lazy(() => import('@/pages/assets/tokens')));
const Nft = withPermission(Loadable(lazy(() => import('@/pages/nft/nft'))));
const AssetsDefiTab = Loadable(lazy(() => import('@/pages/assets/defi')));
const VestingTokens = withPermission(
  Loadable(lazy(() => import('@/pages/assets/vesting-contracts'))),
);
const VestingContract = withPermission(
  Loadable(lazy(() => import('@/pages/assets/vesting-contract'))),
);
const AssetsFiatView = withPermission(Loadable(lazy(() => import('@/pages/assets/fiat'))));

// Accounts
const ConnectedAccounts = withPermission(
  withPermission(Loadable(lazy(() => import('@/pages/accounts/wallets/wallets')))),
);
const AddAccount = withPermission(Loadable(lazy(() => import('@/pages/accounts/add'))));

// Accounting
const AccountingTable = withPermission(
  withPermission(Loadable(lazy(() => import('@/pages/accounting/accounting-table')))),
);
const AccountingOnboard = withPermission(
  Loadable(lazy(() => import('@/pages/accounting/accounting-onboard'))),
);
const AccountingWallets = withPermission(
  Loadable(lazy(() => import('@/pages/accounting/accounting-wallets'))),
);
const AccountingEnrich = withPermission(
  Loadable(lazy(() => import('@/pages/accounting/enrich/enrich'))),
);

// Bookkeeping v2
const BookkeepingV2 = withPermission(
  Loadable(lazy(() => import('@/pages/bookkeeping-v2/bookkeeping-v2'))),
);

// Rules
const Rules = withPermission(Loadable(lazy(() => import('@/pages/rules/rules'))));

// Tax lots
const TaxLots = withPermission(Loadable(lazy(() => import('@/pages/tax-lots/tax-lots'))));
const TaxLot = withPermission(Loadable(lazy(() => import('@/pages/tax-lots/tax-lot'))));

// Reports
const Reports = withPermission(Loadable(lazy(() => import('@/pages/reports/landing-page'))));
const RequestedReports = withPermission(
  Loadable(lazy(() => import('@/pages/reports/requested-reports-page'))),
);
const ClosingPositionsReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/closing-positions-report'))),
);
const TokensClosingPositionsReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/closing-positions-report/tokens'))),
);
const NftClosingPositionsReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/closing-positions-report/nft-collections'))),
);
const WalletsClosingPositionsReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/closing-positions-report/wallets'))),
);
const DefiClosingPositionsReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/closing-positions-report/defi'))),
);
const GainLossReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/gain-loss-report'))),
);
const ProfitLossReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/profit-loss-report'))),
);
const WalletTokenBalancesReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/wallet-token-balances-report'))),
);
const AccountTransactionsReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/account-transaction-report'))),
);
const JournalEntryReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/journal-entry-report'))),
);
const ScheduleDispositionsReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/schedule-disposition-report'))),
);
const CostBasisReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/cost-basis-report'))),
);
const RoyaltyFeesReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/royalty-fees-report'))),
);

const FasbRollForwardReport = withPermission(
  Loadable(lazy(() => import('@/pages/reports/fasb/roll-forward-report/RollForwardReport'))),
);

// Impairment
const Impairment = withPermission(Loadable(lazy(() => import('@/pages/impairment/impairment'))));
const CalculateImpairment = withPermission(
  Loadable(lazy(() => import('@/pages/impairment/calculate'))),
);

// Contacts
const Contacts = withPermission(Loadable(lazy(() => import('@/pages/contacts/contacts'))));

// Setup
const Setup = withPermission(Loadable(lazy(() => import('@/pages/setup/setup'))));
const IntroductionToBookkeeping = withPermission(
  Loadable(lazy(() => import('@/pages/setup/introduction-to-bookkeeping'))),
);
const ImportChartOfAccounts = withPermission(
  Loadable(lazy(() => import('@/pages/setup/import-chart-of-accounts'))),
);
const MapChartOfAccounts = withPermission(
  Loadable(lazy(() => import('@/pages/setup/map-chart-of-accounts'))),
);

// Policy Center
const Policies = withPermission(Loadable(lazy(() => import('@/pages/policy-center/policies'))));
const ChartOfAccounts = withPermission(
  Loadable(lazy(() => import('@/pages/policy-center/chart-accounts'))),
);
const PoliciesSpamView = withPermission(Loadable(lazy(() => import('@/pages/policy-center/spam'))));
const AccountingPolicies = withPermission(
  Loadable(lazy(() => import('@/pages/policy-center/accounting-policies'))),
);
const ImpairmentTesting = withPermission(
  Loadable(lazy(() => import('@/pages/policy-center/impairment-testing'))),
);
const NftValuationPolicies = withPermission(
  Loadable(lazy(() => import('@/pages/policy-center/nft-valuation'))),
);
const PoliciesDeFiView = withPermission(Loadable(lazy(() => import('@/pages/policy-center/defi'))));
const BillApprovalPolicies = withPermission(
  Loadable(lazy(() => import('@/pages/policy-center/BillApproval'))),
);

// Settings
const Settings = withPermission(Loadable(lazy(() => import('@/pages/settings/settings'))));
const Profile = withPermission(Loadable(lazy(() => import('@/pages/settings/Profile'))));
const OrganizationSettings = withPermission(
  Loadable(lazy(() => import('@/pages/settings/Organization'))),
);
const Integrations = withPermission(Loadable(lazy(() => import('@/pages/settings/Integrations'))));
const Usage = withPermission(Loadable(lazy(() => import('@/pages/settings/Usage'))));
const AccountingIntegrationRedirect = withPermission(
  Loadable(lazy(() => import('@/pages/settings/AccountingIntegrationRedirect'))),
);
const AddMember = withPermission(
  Loadable(lazy(() => import('@/pages/setup/roles-and-permissions/AddMember'))),
);
const EditMember = withPermission(
  Loadable(lazy(() => import('@/pages/setup/roles-and-permissions/EditMember'))),
);

// MAIN
const Contact = withPermission(Loadable(lazy(() => import('@/pages/contacts/contact'))));
const Invite = withPermission(Loadable(lazy(() => import('@/pages/invite'))));
const ResetPassword = withPermission(Loadable(lazy(() => import('@/pages/users/reset-password'))));
const CreateAccount = withPermission(Loadable(lazy(() => import('@/pages/users/create-account'))));
const ComingSoon = withPermission(Loadable(lazy(() => import('@/pages/coming-soon'))));
const Page404 = withPermission(Loadable(lazy(() => import('@/pages/notFound404'))));
