import { initializeApp } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  verifyPasswordResetCode as firebaseVerifyPasswordResetCode,
  confirmPasswordReset as firebaseConfirmPasswordReset,
  // GithubAuthProvider,
  GoogleAuthProvider,
  // TwitterAuthProvider,
  signInWithPopup,
  signOut,
  UserCredential,
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Antd
import { message } from 'antd';
import { SESSION_KEYS } from '../utils/constants';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MSG_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = (): Promise<UserCredential> => {
  return new Promise((resolve, reject) => {
    signInWithPopup(auth, googleProvider)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.log('Error', error);

        reject(error);
      });
  });
};

export const registerWithEmailAndPassword = async (email: string, password: string) => {
  createUserWithEmailAndPassword(auth, email, password).catch(({ code }) => {
    console.log('Error', code);

    if (code === 'auth/weak-password') {
      message.error('Please choose a stronger password');
    } else if (code === 'auth/email-already-in-use') {
      message.error('An account already exists under this email address');
    } else {
      message.error('Something went wrong. Please try again.');
    }
  });
};

export const signInWithEmailAndPassword = async (email: string, password: string) => {
  return firebaseSignInWithEmailAndPassword(auth, email, password);
};

export const verifyPasswordResetCode = async (code: string) => {
  return firebaseVerifyPasswordResetCode(auth, code);
};

export const confirmPasswordReset = async (code: string, password: string) => {
  return firebaseConfirmPasswordReset(auth, code, password);
};

const logout = () => {
  window.sessionStorage.removeItem(SESSION_KEYS.currentOrg);
  signOut(auth);
};

export {
  auth,
  logout,
  // signInWithGithub,
  // signInWithTwitter,
  signInWithGoogle,
  app,
  db,
};
