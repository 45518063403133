// React
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

// Internal
import App from './App';
import { ReportProvider } from './contexts/ReportContext';
import { TagsProvider } from './contexts/TagsContext';

// Antd
import { ConfigProvider, message } from 'antd';
import { updateTheme } from './components/DarkModeSwitch';

// Sentry
import * as Sentry from '@sentry/react';

// Segment
import * as SegmentSnippet from '@segment/snippet';

// MISC
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { get } from 'lodash-es';
import { HotkeysProvider } from 'react-hotkeys-hook';

// Ag-grid
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { LicenseManager } from 'ag-grid-enterprise';

// Styles
import './styles/styles.scss';
// Sass variables
import antdComponentsTheme from './styles/overrides/antd/components-theme';
import { SASS_VARIABLES } from './utils/styles';
import { NonPersistentQueryKeys } from './utils/constants';
import FullPageSplash from './components/FullPageSplash';

import ERROR_IMAGE from './img/pages/404/image.svg';

// Ag-grid license
LicenseManager.setLicenseKey(
  // Valid until July 12, 2025
  'Using_this_{AG_Grid}_Enterprise_key_{AG-059751}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Integral}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Integral}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Integral}_need_to_be_licensed___{Integral}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{12_July_2025}____[v3]_[01]_MTc1MjI3NDgwMDAwMA==b9aeccf8460c5f193aaa3def6247cd00',
);

updateTabTitle();

// Only run sentry in prod
if (import.meta.env.VITE_APP_BUILD_TYPE !== 'development') {
  Sentry.init({
    dsn: 'https://0950cb67869a43dfa742b9ba75031020@o1260739.ingest.sentry.io/6438778',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', 'api-tq6fnzufaq-uc.a.run.app'],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  Sentry.addTracingExtensions();
}

// Configure Segment TODO: change logic - adjusted here for testing only
let segmentSnippet: string | undefined = undefined;
if (import.meta.env.VITE_ANALYTICS_ENABLED === 'true') {
  segmentSnippet = SegmentSnippet.min({
    apiKey: import.meta.env.VITE_SEGMENT_API_KEY,
    page: false,
  });

  const script = document.createElement('script');

  if (!!script) {
    script.text = segmentSnippet;
    document.head.appendChild(script);
  }
}

/*
 * Check if dark-mode needs to be activated
 */
if (localStorage && localStorage.getItem) {
  const themePreference = localStorage.getItem('integral__theme');
  if (themePreference === 'dark') {
    updateTheme(true);
  }
}

export const handleMutationErrorDefault = (error: any) => {
  console.error('Error', error);
  message.error(get(error, 'message') || get(error, 'param') || 'Something went wrong');
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 1 week
      staleTime: 1000 * 60 * 3, // 3 minutes
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onError: (error: any) => {
        console.error('Error', error);
        message.error(get(error, 'param') || 'Something went wrong');
      },
    },
    mutations: {
      onError: handleMutationErrorDefault,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const container = document.getElementById('root');
const root = createRoot(container!);

if (import.meta.env.VITE_MAINTENANCE_MODE === 'true') {
  root.render(
    <FullPageSplash
      actions={null}
      backgroundStyles={{
        backgroundImage: `url(${ERROR_IMAGE})`,
        backgroundColor: '#fff',
        backgroundSize: 'auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      title="Sorry, we are undergoing brief maintenance."
      subTitle="We will be back up again in a few minutes. Thank you for your patience."
      pageTitle="We'll be right back"
    />,
  );
} else {
  root.render(
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        dehydrateOptions: {
          shouldDehydrateQuery: (query) => {
            const queryIsReadyForPersistance = query.state.status === 'success';
            if (queryIsReadyForPersistance) {
              const { queryKey } = query;
              // do not store files in local storage
              const excludeFromPersisting = queryKey.includes(
                NonPersistentQueryKeys.AttachmentsFiles,
              );
              return !excludeFromPersisting;
            }
            return queryIsReadyForPersistance;
          },
        },
      }}
    >
      <BrowserRouter>
        <HotkeysProvider>
          <TagsProvider>
            <ReportProvider>
              <ConfigProvider
                theme={{
                  token: {
                    ...SASS_VARIABLES,
                    motion: false,
                  },
                  components: antdComponentsTheme,
                }}
              >
                <App />
              </ConfigProvider>
            </ReportProvider>
          </TagsProvider>
        </HotkeysProvider>
      </BrowserRouter>
    </PersistQueryClientProvider>,
  );
}

/**
 * Updates the tab title based on the current environment and build type
 */
function updateTabTitle() {
  const isProd = process.env.VITE_ENV === 'production';
  const isStaging = process.env.VITE_ENV === 'staging';

  let serverSuffix = '';
  if (isProd) {
    serverSuffix = 'prod';
  } else if (isStaging) {
    serverSuffix = 'staging';
  }

  if (import.meta.env.VITE_APP_BUILD_TYPE === 'development') {
    // Update the tab title for development, e.g. '🚧 local-prod | Integral'
    document.title = `${isProd ? `🚧 ` : ''}local${
      serverSuffix ? `-${serverSuffix}` : ''
    } | Integral`;
  } else {
    const DEPLOY_PREVIEW_PREFIX = 'deploy-preview';
    const domainName = window.location.hostname;
    const deployPreviewRegex = new RegExp(`${DEPLOY_PREVIEW_PREFIX}-(\\d+)`);
    const isDeployPreview = domainName.includes(DEPLOY_PREVIEW_PREFIX);

    if (isDeployPreview) {
      // Extract the deploy-preview number from the domain name
      const deployPreviewMatch = domainName.match(deployPreviewRegex);
      const deployPreviewNumber = deployPreviewMatch ? deployPreviewMatch[1] : null;

      // Update the tab title for deploy-previews, e.g. 'DP-1020 (prod)'
      const dpPrefix = 'DP';
      const dpSuffix = deployPreviewNumber ? `-${deployPreviewNumber}` : '';
      serverSuffix = serverSuffix ? ` (${serverSuffix})` : '';
      document.title = `${dpPrefix}${dpSuffix}${serverSuffix}`;
    }
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

export const SUPPORT_EMAIL_ADDRESS = 'hi@integral.xyz';
