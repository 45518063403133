import { syncAccounts } from '@/api/accounts';
import message from '@/components/AntdCustomized/message';
import { usePrevious } from '@/hooks/usePrevious';
import { db } from '@/services/firebase';
import { sub } from 'date-fns';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import { useOrganizations } from './OrganizationsContext';

/*
 * `started` = syncing process started but we haven't received the confirmation that the worlflow has been added to firestore
 * `syncing` = confirmation received from firestore, syncing in progress
 * `synced` = received confirmation that the workflow has been completed
 * `idle` = default status, nothing interesting happening or running
 * */
type SyncingStatus = 'started' | 'syncing' | 'synced' | 'idle';

const AccountsSyncingContext = createContext<{
  accountsSyncing: SyncingStatus;
  trackSyncAccountsJob: (workflowId: string) => void;
  syncAllAccounts: () => void;
  finalizeSyncing: () => void;
}>({
  accountsSyncing: 'idle',
  trackSyncAccountsJob: (_: string) => {},
  syncAllAccounts: () => {},
  finalizeSyncing: () => {},
});

const queryLastNotificationJob = async (orgId: string) => {
  // Query the most recent sync workflow start for the specified org. Ignore workflows older than 6 hours, as they should be already completed.
  const q = query(
    collection(db, 'orgNotifications', orgId, 'notifications'),
    where('type', '==', 'SyncWorkflowStarted'),
    where('createdAt', '>', sub(new Date(), { hours: 6 })),
    orderBy('createdAt', 'desc'),
    limit(1),
  );
  const querySnapshot = await getDocs(q);
  let lastWorkflowId: string = '';

  if (querySnapshot.empty) {
    // No sync workflow start notifications, treat it as "synced"
    return { synced: true, workflowId: lastWorkflowId };
  }

  querySnapshot.forEach((doc) => {
    const {
      data: { workflowId },
    } = doc.data();
    lastWorkflowId = workflowId;
  });

  const jobSyncedRef = doc(
    db,
    'orgNotifications',
    orgId,
    'notifications',
    `${lastWorkflowId}-completed`,
  );

  const synced = await getDoc(jobSyncedRef);

  // if it has both started and finished, nothing to notify
  if (synced.data()) {
    return { synced: true, workflowId: lastWorkflowId };
  }

  return { synced: false, workflowId: lastWorkflowId };
};

function AccountSyncingProvider({ children }: { children: React.ReactNode }) {
  const { organization } = useOrganizations();
  const [syncingStatus, setSyncingStatus] = useState<SyncingStatus>('idle');

  const trackStartedAccountsJob = useCallback(
    (workflowId: string) => {
      // Listen to firebase `job-started` document
      const jobStartedNotificationUnsubscribe = onSnapshot(
        doc(db, 'orgNotifications', organization.id, 'notifications', `${workflowId}-started`),
        (doc) => {
          if (doc.data()) {
            setSyncingStatus('syncing');
            jobStartedNotificationUnsubscribe();
          }
        },
        (err) => {
          if (err) {
            jobStartedNotificationUnsubscribe();
          }
        },
      );
    },
    [organization],
  );

  const trackCompletedAccountsJob = useCallback(
    (workflowId: string) => {
      // Listen to firebase `job-started` document
      const jobCompletedNotificationUnsubscribe = onSnapshot(
        doc(db, 'orgNotifications', organization.id, 'notifications', `${workflowId}-completed`),
        (doc) => {
          if (doc.data()) {
            setSyncingStatus('synced');
            jobCompletedNotificationUnsubscribe();
          }
        },
        (err) => {
          if (err) {
            jobCompletedNotificationUnsubscribe();
          }
        },
      );
    },
    [organization],
  );

  useEffect(() => {
    queryLastNotificationJob(organization.id)
      .then((result) => {
        if (!result.synced) {
          setSyncingStatus('syncing');
          trackCompletedAccountsJob(result.workflowId);
        }
      })
      .catch((err) => {
        console.error('Error querying firestore for the last account syncing job', err);
      });
  }, [organization, trackCompletedAccountsJob]);

  const trackSyncAccountsJob = useCallback(
    (workflowId: string) => {
      trackStartedAccountsJob(workflowId);
      trackCompletedAccountsJob(workflowId);
    },
    [trackStartedAccountsJob, trackCompletedAccountsJob],
  );

  const handleSyncAllAccounts = useCallback(async () => {
    try {
      setSyncingStatus('started');
      const { body } = await syncAccounts();
      trackSyncAccountsJob(body.meta.workflowId);
    } catch (err) {
      setSyncingStatus('idle');
      message.error('Error syncing wallets');
    }
  }, [trackSyncAccountsJob]);

  const handleFinalizeSyncing = useCallback(() => {
    if (syncingStatus === 'synced') {
      setSyncingStatus('idle');
    }
  }, [syncingStatus]);

  const value = {
    accountsSyncing: syncingStatus,
    trackSyncAccountsJob,
    syncAllAccounts: handleSyncAllAccounts,
    finalizeSyncing: handleFinalizeSyncing,
  };

  return (
    <AccountsSyncingContext.Provider value={value}>{children}</AccountsSyncingContext.Provider>
  );
}

export default AccountSyncingProvider;

export const useAccountsSyncing = (params?: {
  onSync?: () => void;
  onStartSyncing?: () => void;
}) => {
  const { accountsSyncing, finalizeSyncing, syncAllAccounts, trackSyncAccountsJob } =
    useContext(AccountsSyncingContext);
  const prevSync = usePrevious(accountsSyncing);

  useEffect(() => {
    // trigger event when user starts a syncing process
    if (prevSync === 'idle' && accountsSyncing === 'started') {
      params?.onStartSyncing?.();
    }

    // if it was changed from syncing to synced means that just finished syncing
    if (prevSync === 'syncing' && accountsSyncing === 'synced') {
      params?.onSync?.();
      finalizeSyncing();
    }
  }, [prevSync, accountsSyncing, finalizeSyncing]);

  return {
    syncAllAccounts: syncAllAccounts,
    accountsSyncing: accountsSyncing === 'started' || accountsSyncing === 'syncing',
    trackSyncAccountsJob,
  };
};
