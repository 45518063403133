import React from 'react';

import { Link } from 'react-router-dom';

// Internal
import Typography from '../../components/Typography';

// Antd
import { Space } from 'antd';

// Icons
import { ArrowLeft } from '@carbon/icons-react';

// Styles
import styles from './login.module.scss';

function Register() {
  // const onFinish = (values: any) => {
  //   registerWithEmailAndPassword(values.email, values.password);
  // };

  return (
    <>
      <Space direction="vertical" className={styles.loginPage__container__form__methods}>
        <Typography.Title level={1}>Sign up</Typography.Title>

        <Typography.Text>Integral is currently on a closed beta.</Typography.Text>

        <Typography.Text color="tertiary" style={{ margin: 0 }}>
          Send us an email at <a href="mailto:hi@integral.xyz">hi@integral.xyz</a>, and we'll let
          you know once our beta opens up.
        </Typography.Text>

        <Link to="/" style={{ display: 'block', marginTop: '2rem' }}>
          <ArrowLeft
            style={{
              height: '1.15rem',
              marginTop: '-0.15rem',
              marginLeft: '-0.45rem',
              marginRight: '0.5rem',
            }}
          />

          <Typography.Text strong inline>
            Go back
          </Typography.Text>
        </Link>
      </Space>
    </>
  );
}
export default Register;
