/**
 * classes to be used for Antd components
 *
 * For some components, we have yet to create a wrapper to expose custom props.
 * If we need to add classes to any of these components, add them here.
 */

export const ANTD_CLASSES = {
  drawer: {
    contentWrapper: 'ant-drawer-content-wrapper',
    classNames: {
      footerPaddingBlockStart: 'ant-drawer-footer__padding-block-start',
    },
  },
  card: {
    classNames: {
      suppressPadding: 'no-padding',

      /**
       * Allows the card-extra to allign to the start of the card-head;
       */
      suppressCardExtraMarginInlineStart: 'card-extra--suppress-margin-inline-start',

      /**
       * Removes the bottom border for the card-head and adjusts padding so that it matches the padding of the card-body;
       */
      suppressCardHeadBorderBottom: 'card-head--suppress-border-bottom',

      /**
       * Hides the card body
       */
      hideCardBody: 'card-body--hidden',
    },
  },
  modal: {
    innerContent: 'ant-modal-inner-content',
  },
  select: {
    items: {
      indented: 'ant-select-item--indented',
    },
    popupclassNames: {
      /**
       * Removes padding inside the dropdown, and adds bottom-border to each select-item;
       */
      noPadding: 'ant-select-dropdown--no-padding',

      /**
       * Removes padding-inline inside the dropdown
       */
      noPaddingInline: 'ant-select-dropdown--no-padding-inline',

      /**
       * Sets the select item cursor to 'default
       */
      cursorDefault: 'ant-select-dropdown--cursor--default',
    },
    background: {
      /**
       * Sets background on error state
       */
      error: 'ant-select-background-status--error',

      /**
       * Sets background on warning state
       */
      warning: 'ant-select-background-status--warning',
    },
  },
} as const;
