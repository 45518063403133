import { ApiResponse, deleteRequest, getRequest, postRequest, putRequest } from './request';

export enum VestingTimeUnitEnum {
  seconds = 'seconds',
  blocks = 'blocks',
}

export enum VestingEventTypeEnum {
  scheduled = 'scheduled',
  claimed = 'claimed',
}

export enum VestingPriceTypeEnum {
  scheduled = 'scheduled',
  claimed = 'claimed',
  unlocked = 'unlocked',
}

// TODO: figure out which properties are optional
export interface VestingContract {
  id: string;

  createdAt: Date;
  updatedAt: Date;

  name: string;
  beneficiaryId: string;
  beneficiaryAddress: string;
  contractAddress: 'string'; // canonical?
  tokenSymbol: string;
  tokenDecimals: number;
  tokenAddress: string;
  startTime: string;
  cliffTime: string;
  endTime: string;
  vestingPeriod: string;
  timeUnit: VestingTimeUnitEnum;
  totalVesting: string;
  totalClaimed: string;
  totalUnlocked: string;
  vestedAtCliff: string;
}

export interface VestingContractEvent {
  id: string;

  createdAt: Date;
  updatedAt: Date;

  vestingContractId: string;
  startTime: string;
  endTime: string;
  cliffTime: string;
  vestingPeriod: string;
  timeUnit: VestingTimeUnitEnum;
  totalVesting: string;
  totalClaimed: string;
  totalUnlocked: string;
  vestedAtCliff: string;
  tokenSymbol: string;
  tokenDecimals: number;
  tokenAddress: string;
  eventType: VestingEventTypeEnum;
  txnBlockNumber: string;
  txnHash: string;
  txnTimestamp: Date;
  extra?: {
    [key: string]: any;
  };
}

export interface VestingContractPrice {
  id: string;

  createdAt: Date;
  updatedAt: Date;

  vestingContractId: string;
  vestingEventId: string | null;
  priceType: VestingPriceTypeEnum;
  eventTime: string;
  timeUnit: VestingTimeUnitEnum;
  amount: string;
  price: string | null;
  hasMissingVestPrices?: boolean;
}

export interface CreateVestingContractDto {
  name: string;
  beneficiaryId: string;
  contractAddress: string;
}

export interface GetVestingContractDto {
  asOf?: number;
}

export interface GetVestingContractsDto {
  asOf?: number;
}

export interface AnalyzeVestingContractDto {
  chain: string;
  contractAddress: string;
  beneficiaryAddress: string;
}

export interface GetVestingContractPricesDto {
  page?: string;
  perPage?: number;
}

export interface UpdateVestingContractPriceDto {
  price: string | null;
}

export interface UpdateVestingContractPricesDto extends UpdateVestingContractPriceDto {
  ids: string[];
}

export const createVestingContract = (dto: CreateVestingContractDto) =>
  postRequest('/vesting/contracts', dto);

export const getVestingContracts = (dto?: GetVestingContractDto) =>
  getRequest<ApiResponse<VestingContract[]>>('/vesting/contracts', dto || {});

export const getVestingContract = (id: string, dto?: GetVestingContractsDto) =>
  getRequest<ApiResponse<VestingContract>>(`/vesting/contracts/${id}`, dto || {});

export const deleteVestingContract = (id: string) =>
  deleteRequest<ApiResponse<VestingContractPrice>>(`/vesting/contracts/${id}`, {});

export const analyzeVestContract = (dto: AnalyzeVestingContractDto) =>
  postRequest<ApiResponse<VestingContract[]>>('/vesting/contracts/analyze', dto);

export const getVestingContractPrices = (id: string, dto?: GetVestingContractPricesDto) =>
  getRequest<ApiResponse<VestingContractPrice[]>>(`/vesting/contracts/${id}/prices`, dto || {});

export const getVestingContractPrice = (id: string, vestingContractPriceId: string) =>
  getRequest<ApiResponse<VestingContractPrice>>(
    `/vesting/contracts/${id}/prices/${vestingContractPriceId}`,
    {},
  );

export const updateVestingContractPrices = (id: string, dto?: UpdateVestingContractPricesDto) =>
  putRequest<ApiResponse<VestingContractPrice>>(
    `/vesting/contracts/${id}/prices/batch/price`,
    dto || {},
  );

export const updateVestingContractPrice = (
  vestingContractId: string,
  vestingContractPriceId: string,
  dto?: UpdateVestingContractPriceDto,
) =>
  putRequest<ApiResponse<VestingContractPrice>>(
    `/vesting/contracts/${vestingContractId}/prices/${vestingContractPriceId}/price`,
    dto || {},
  );
