// Internal
import classNames from 'classnames';
import withPermission from '../HOCs/withPermission';

// Antd
import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd';

// Styles
import './Button.scss';

interface ElementButtonProps extends Omit<AntdButtonProps, 'size' | 'type'> {
  size?: 'xsmall' | 'small' | 'middle' | 'large';
  type?: 'default' | 'primary' | 'dashed' | 'link' | 'text' | 'link-secondary' | 'link-tertiary';
  paddingless?: boolean;
  iconPosition?: 'lead' | 'trail';
}

const Button = (props: ElementButtonProps) => {
  const { className, size, type, paddingless, iconPosition, ...restProps } = props;

  return (
    <AntdButton
      {...restProps}
      className={classNames(className, {
        'size-xsmall': size === 'xsmall',
        'link-secondary': type === 'link-secondary',
        'link-tertiary': type === 'link-tertiary',
        'ant-btn--paddingless': paddingless,
        'ant-btn__icon--position--trail': iconPosition === 'trail',
      })}
      size={size === 'xsmall' ? undefined : size}
      type={type === 'link-secondary' || type === 'link-tertiary' ? 'link' : type}
    />
  );
};

const ButtonWithPermission = withPermission(Button, { permissionType: 'component' });

export type ButtonProps = React.ComponentProps<typeof ButtonWithPermission>;

export default ButtonWithPermission;
