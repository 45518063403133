// Internal
import {
  AccountingClass,
  AccountingSettingsDto,
  DailyIncomeExpense,
  IncomeExpenseResponse,
  getIncomeExpenseCategoriesDto,
} from './__types/accounting.types';
import { baMappingCountsQueryKey } from './book-accounts';
import { ApiResponse, getRequest, patchRequest } from './request';

// MISC
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

// export types
export * from './__types/accounting.types';

// Query keys
export const accountingQueryKeys = {
  all: ['accounting'] as const,
  classes: () => [...accountingQueryKeys.all, 'classes'] as const,
  settings: () => [...accountingQueryKeys.all, 'settings'] as const,
};

export const getDailyIncomeExpenses = (dto: getIncomeExpenseCategoriesDto) => {
  return getRequest<ApiResponse<DailyIncomeExpense[]>>('/accounting/income-expense/day', dto);
};

export const getIncomeExpenseCategories = (dto: getIncomeExpenseCategoriesDto) => {
  return getRequest<ApiResponse<IncomeExpenseResponse>>('/accounting/income-expense/category', dto);
};

export const getIncomeExpenseContacts = (dto: getIncomeExpenseCategoriesDto) => {
  return getRequest<ApiResponse<IncomeExpenseResponse>>('/accounting/income-expense/contact', dto);
};

export const getAccountingClasses = () => {
  return getRequest<ApiResponse<AccountingClass[]>>('/accounting/classes', null);
};

interface AccountingClassData {
  list: AccountingClass[];
  findById: (idToFind: string) => AccountingClass | null;
}
export const useGetAccountingClasses = () => {
  const accountingClassesQuery = useQuery<AccountingClassData, Error>({
    queryKey: accountingQueryKeys.classes(),
    queryFn: async () => {
      const { body } = await getAccountingClasses();
      const list = body.data;

      return {
        list,
        findById: (idToFind: string) => {
          let _result = null;
          const _find = (_classes: AccountingClass[]) => {
            for (let i = 0; i < _classes.length; i++) {
              const _class = _classes[i];

              if (_class.id === idToFind) {
                _result = _class;
                break;
              }

              if (_class.children) {
                _find(_class.children);
              }
            }
          };

          // Start initial find
          _find(list);

          return _result;
        },
      };
    },
    placeholderData: { list: [], findById: () => null },
  });

  return {
    ...accountingClassesQuery,
    query: accountingClassesQuery,
    data: accountingClassesQuery.data?.list,
    findById: accountingClassesQuery.data?.findById,
  };
};

const _getAccountingSettings = () => {
  return getRequest<ApiResponse<AccountingSettingsDto>>('/accounting/settings', null);
};
export const useGetAccountingSettings = () => {
  const settingsQuery = useQuery<AccountingSettingsDto, Error>({
    queryKey: accountingQueryKeys.settings(),
    queryFn: async () => {
      const { body } = await _getAccountingSettings();
      return body.data;
    },
  });

  return {
    query: settingsQuery,
    data: settingsQuery.data,
    loading: settingsQuery.isLoading,
  };
};

export const updateAccountingSettings = (dto: Partial<AccountingSettingsDto>) => {
  return patchRequest('/accounting/settings', dto);
};
export const useUpdateAccountingSettings = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (dto: Partial<AccountingSettingsDto>) => {
      const { body } = await updateAccountingSettings(dto);
      return body.data;
    },
    onSuccess: (data: AccountingSettingsDto, dto: Partial<AccountingSettingsDto>) => {
      if (
        typeof dto.feeBookAccount !== 'undefined' ||
        typeof dto.gainLossBookAccount !== 'undefined' ||
        typeof dto.bookAccountMappingStrategy !== 'undefined'
      ) {
        // refresh the mapping counts if we change any of these
        queryClient.invalidateQueries(baMappingCountsQueryKey);
      }
      queryClient.setQueryData(accountingQueryKeys.settings(), data);
    },
  });

  return mutation;
};
