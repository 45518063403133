export enum ReadOnlyPermission {
  ReadOnly = 'Read-Only',
}

export enum SetupPermission {
  ManageWallets = 'Setup.Manage Wallets',
  ManageERP = 'Setup.Manage ERP',
}

export enum BookkeepingPermission {
  EditTransactions = 'Bookkeeping.Edit Transactions',
  ManageContacts = 'Bookkeeping.Manage Contacts',
  ManageRules = 'Bookkeeping.Manage Rules',
  SyncToERP = 'Bookkeeping.Sync To ERP',
  DownloadReports = 'Bookkeeping.Download Reports',
}

export enum PaymentsPermission {
  CreateBills = 'Payments.Create Bills',
  ReconcileBills = 'Payments.Reconcile Bills',
  ApproveBills = 'Payments.Approve Bills',
  PayBills = 'Payments.Pay Bills',
}

export enum OrganizationsPermission {
  ManageUsers = 'Organizations.Manage Users',
  ManageRoles = 'Organizations.Manage Roles',
  AssignUserRoles = 'Organizations.Assign User Roles',
  ManageOrganizations = 'Organizations.Manage Organizations',
}

export type PermissionTypes =
  | ReadOnlyPermission
  | SetupPermission
  | BookkeepingPermission
  | PaymentsPermission
  | OrganizationsPermission;

export const PermissionsList = {
  ReadOnlyPermission: ReadOnlyPermission,
  SetupPermission: SetupPermission,
  BookkeepingPermission: BookkeepingPermission,
  PaymentsPermission: PaymentsPermission,
  OrganizationsPermission: OrganizationsPermission,
};

export enum RoleName {
  Viewer = 'Viewer',
  Auditor = 'Auditor',
  Approver = 'Approver',
  Bookkeeper = 'Bookkeeper',
  FinanceManager = 'FinanceManager',
  Admin = 'Admin',
}
