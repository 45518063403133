import React from 'react';

// Styles
import './FeaturedIcon.scss';

export type FeaturedIconColors = 'success' | 'primary' | 'gray' | 'danger';

interface FeaturedIconProps {
  color?: FeaturedIconColors;
  icon: React.ReactNode;
  style?: React.CSSProperties;
  size?: string | number;
}

export default function FeaturedIcon({ color, icon, style, size }: FeaturedIconProps) {
  return (
    <div
      className={`featured-icon__circle ${color !== 'primary' ? `color-${color}` : ''}`}
      style={{ ...style, width: size, height: size }}
    >
      {icon}
    </div>
  );
}
