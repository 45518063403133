import React from 'react';

// Internals
import EmptyState from './EmptyState';
import FeaturedIcon from './FeaturedIcon';
import PageHeader from './PageHeader';

// Icons
import { Time } from '@carbon/icons-react';

export const ComingSoonSection = ({ headerTitle }: { headerTitle: string }) => (
  <>
    <PageHeader title={headerTitle}></PageHeader>

    <EmptyState
      title="Coming Soon"
      subTitle="We’re working on this page and will release it soon. Contact us if you need any help."
      icon={<FeaturedIcon icon={<Time />} style={{ margin: 'auto' }} />}
    />
  </>
);
