import { ApiResponse, getRequest, postRequest } from './request';

export interface PasswordResetDto {
  email: string;
}

export interface IUser {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  type?: string;
  accountingFirm?: string;
}

// Query keys
export const authQueryKeys = {
  all: ['auth'] as const,
};

export const sendPasswordResetEmail = (dto?: PasswordResetDto) =>
  postRequest<string>(`/auth/v2/password-reset`, dto);

export const getUser = () => getRequest<ApiResponse<IUser>>('/auth/user', {});
