// React
import React from 'react';

// Internal
import Typography from '../Typography';
import Button, { ButtonProps } from '../AntdCustomized/Button';

// Antd
import { Divider } from 'antd';

// Icons
import { ArrowLeft } from '@carbon/icons-react';

// Styles
import styles from './PageHeader.module.scss';

// Misc
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

export interface PageHeaderProps {
  back?: {
    text: string;
    link: string;
  };
  title: string | JSX.Element;
  extra?: React.ReactNode;
  footer?: React.ReactNode;
  body?: React.ReactNode;
  suppressDivider?: boolean;
  style?: React.CSSProperties;
}

const PageHeader = ({
  title,
  back,
  extra,
  footer,
  suppressDivider,
  style,
  body,
}: PageHeaderProps) => {
  const navigate = useNavigate();

  const onBackClick = () => {
    if (!back || !back.link) return;
    navigate(back.link);
  };

  return (
    <div style={style} className={classNames(styles['page-header'])}>
      <div className={styles.columns}>
        <div className={styles['title-container']}>
          {back && (
            <Button size="small" type="default" onClick={onBackClick}>
              {<ArrowLeft />} {back.text}
            </Button>
          )}
          {typeof title === 'string' ? (
            <Typography.Title level={2}>{title}</Typography.Title>
          ) : (
            title
          )}
        </div>
        {extra && <div className={styles['extra-wrapper']}>{extra}</div>}
      </div>
      {body}
      {footer || (!suppressDivider ? <Divider style={{ margin: 0 }} /> : null)}
    </div>
  );
};

PageHeader.Button = (props: Omit<ButtonProps, 'size'>) => {
  return <Button {...props} size="small" />;
};

export default PageHeader;
