import React, { useCallback, useMemo } from 'react';

// Internal
import { BookAccount, getBankBookAccounts } from '@/api/book-accounts';

// MISC
import { groupBy } from 'lodash-es';
import type { Dictionary } from 'lodash';

type BookAccountsProviderProps = { children: React.ReactNode };

export type CategorizedBookAccount = { Category: string; subCategory: BookAccount[] };

const BookAccountsStateContext = React.createContext<
  | {
      bookAccounts: BookAccount[] | null;
      categorizedBookAccounts: CategorizedBookAccount[] | null;
      groupedBookAccounts: Dictionary<BookAccount[]> | null;
      transactionBookAccounts: CategorizedBookAccount[] | null;
      reFetchBookAccounts: Function;
      loading: boolean;
    }
  | undefined
>(undefined);

function BookAccountsProvider({ children }: BookAccountsProviderProps) {
  const [bookAccounts, setBookAccounts] = React.useState<BookAccount[] | null>(null);
  const [loading, setLoading] = React.useState(false);

  const fetchBookAccounts = useCallback(() => {
    setLoading(true);

    getBankBookAccounts()
      .then(({ body }) => {
        setBookAccounts(body.data);
      })
      .catch((err) => {
        console.error('Error fetching book accounts', err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(fetchBookAccounts, []);

  const categorizedBookAccounts = useMemo(() => {
    if (bookAccounts) {
      bookAccounts.forEach((object: any) => {
        object.category =
          object?.name?.indexOf('-') > 0
            ? object?.name?.substring(0, object?.name?.indexOf('-')).trim()
            : object?.name?.trim();
      });

      return Object.entries(groupBy(bookAccounts, 'category')).map(([key, value]) => ({
        Category: key,
        subCategory: value,
      }));
    }

    return null;
  }, [bookAccounts]);

  const groupedBookAccounts = useMemo(() => {
    if (bookAccounts) {
      return groupBy(bookAccounts, 'fullyQualifiedCategory');
    }

    return null;
  }, [bookAccounts]);

  const transactionBookAccounts = useMemo(() => {
    if (bookAccounts) {
      bookAccounts.forEach((object: any) => {
        object.category = object?.fullyQualifiedCategory?.substring(
          0,
          object?.fullyQualifiedCategory?.indexOf('.'),
        );
      });

      return Object.entries(groupBy(bookAccounts, 'category')).map(([key, value]) => ({
        Category: key,
        subCategory: value,
      }));
    }

    return null;
  }, [bookAccounts]);

  const value = {
    bookAccounts,
    categorizedBookAccounts,
    groupedBookAccounts,
    transactionBookAccounts,
    reFetchBookAccounts: fetchBookAccounts,
    loading,
  };

  return (
    <BookAccountsStateContext.Provider value={value}>{children}</BookAccountsStateContext.Provider>
  );
}

function useBookAccounts() {
  const context = React.useContext(BookAccountsStateContext);
  if (context === undefined) {
    throw new Error('useBookAccounts must be used within a BookAccountsProvider');
  }
  return context;
}

export { BookAccountsProvider, useBookAccounts };
