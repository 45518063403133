// Internal
import {
  BookkeepingPermission,
  OrganizationsPermission,
  PaymentsPermission,
  ReadOnlyPermission,
  SetupPermission,
} from '@/api/__types/roles.types';

export const RBAC_MAP = {
  BookkeepingV2: {
    // General actions
    BulkActions: {
      // disable grid `headerCheckboxSelection` + `checkboxSelection` to prevent bulk actions UI from opening
      // TODO: need missing permissions tooltip for disabled checkboxes
      permission: BookkeepingPermission.EditTransactions,
    },
    SyncToERP: {
      permission: BookkeepingPermission.SyncToERP,
    },
    StopSync: {
      permission: BookkeepingPermission.SyncToERP,
    },
    Export: {
      permission: BookkeepingPermission.SyncToERP,
    },
    StopExport: {
      permission: BookkeepingPermission.SyncToERP,
    },
    ReviewTransactions: {
      // move transactions from "For Review -> "Reviewed"
      permission: BookkeepingPermission.EditTransactions,
    },
    StopUpdate: {
      permission: BookkeepingPermission.EditTransactions,
    },
    ConnectToERP: {
      permission: SetupPermission.ManageERP,
    },
    RecalculateGainLoss: {
      permission: BookkeepingPermission.EditTransactions,
    },

    // Column actions
    EditGLAccount: {
      permission: BookkeepingPermission.EditTransactions,
    },
    EditMemo: {
      // TODO: need missing permissions tooltip when column not using `cellRenderer`
      permission: BookkeepingPermission.EditTransactions,
    },
    EditClass: {
      permission: BookkeepingPermission.EditTransactions,
    },
    EditTransactionStatus: {
      permission: BookkeepingPermission.EditTransactions,
    },
    UngroupTransactions: { permission: BookkeepingPermission.EditTransactions },

    PriceUpdateModal: {
      EditPricing: {
        permission: BookkeepingPermission.EditTransactions,
      },
      EditUnitPrice: {
        permission: BookkeepingPermission.EditTransactions,
      },
      EditValue: {
        permission: BookkeepingPermission.EditTransactions,
      },
      UpdateButton: {
        permission: BookkeepingPermission.EditTransactions,
      },
    },
  },

  Treasury: {
    WalletsTab: {
      UploadCSV: {
        permission: BookkeepingPermission.EditTransactions,
      },
      ViewCSVImportHistory: {
        permission: ReadOnlyPermission.ReadOnly,
      },
    },
    CurrenciesTab: {
      MarkAsSpam: {
        permission: BookkeepingPermission.EditTransactions,
      },
    },
    CSVImportHistory: {
      Download: {
        permission: BookkeepingPermission.DownloadReports,
      },
      Delete: {
        permission: BookkeepingPermission.EditTransactions,
      },
    },
  },

  Wallets: {
    SingleWallet: {
      AddWallet: {
        permission: SetupPermission.ManageWallets,
      },
      AddAccount: {
        permission: SetupPermission.ManageWallets,
      },
      Rename: { permission: SetupPermission.ManageWallets },
      EditDescription: {
        permission: SetupPermission.ManageWallets,
      },
      EditOwner: {
        permission: SetupPermission.ManageWallets,
      },
      EditSigningKey: {
        permission: SetupPermission.ManageWallets,
      },
      DeleteWallet: {
        permission: SetupPermission.ManageWallets,
      },
    },
    SyncAll: {
      permission: SetupPermission.ManageWallets,
    },
    ExportAsCSV: {
      permission: BookkeepingPermission.DownloadReports,
    },
    GroupedBySource: {
      BulkDeleteWallets: {
        permission: SetupPermission.ManageWallets,
      },
    },
    GroupedByStack: {
      MoveWalletToStackViaDrag: {
        permission: SetupPermission.ManageWallets,
      },
      BulkMoveWalletsToStack: {
        permission: SetupPermission.ManageWallets,
      },
      BulkMoveWalletToNewStack: {
        permission: SetupPermission.ManageWallets,
      },
      CreateStack: {
        permission: SetupPermission.ManageWallets,
      },
      RenameStack: {
        permission: SetupPermission.ManageWallets,
      },
      EditStackIcon: {
        permission: SetupPermission.ManageWallets,
      },
      ReorderStacks: {
        permission: SetupPermission.ManageWallets,
      },
      DeleteStack: {
        permission: SetupPermission.ManageWallets,
      },
    },
  },

  Assets: {
    NFT: {
      IndividualActions: {
        EditPricingMethod: {
          permission: BookkeepingPermission.EditTransactions,
        },
      },
      BulkActions: {
        EditPricingMethod: {
          permission: BookkeepingPermission.EditTransactions,
        },
      },
    },

    Vesting: {
      Detail: {
        Refresh: {
          permission: BookkeepingPermission.EditTransactions,
        },
        Edit: {
          permission: BookkeepingPermission.EditTransactions,
        },
        Remove: {
          permission: BookkeepingPermission.EditTransactions,
        },
      },
      InvidualActions: {
        EditCostBasis: {
          permission: BookkeepingPermission.EditTransactions,
        },
      },

      BulkActions: {
        EditCostBasis: {
          permission: BookkeepingPermission.EditTransactions,
        },
      },
    },
  },

  Impairment: {
    CreateImpairmentTest: {
      permission: BookkeepingPermission.EditTransactions,
    },
    MainImpairmentTable: {
      ReviewImpairment: {
        permission: BookkeepingPermission.EditTransactions,
      },
      DeleteImpairment: {
        permission: BookkeepingPermission.EditTransactions,
      },
      ExportCSV: {
        permission: BookkeepingPermission.DownloadReports,
      },
    },
    ImpairmentReview: {
      SaveForLater: {
        permission: BookkeepingPermission.DownloadReports,
      },
      ExportCSV: {
        permission: BookkeepingPermission.DownloadReports,
      },
      ApplyImpairment: {
        permission: BookkeepingPermission.EditTransactions,
      },
      DiscardImpairment: {
        permission: BookkeepingPermission.EditTransactions,
      },
    },
  },

  NFTRoyalties: {
    TrackCollection: {
      permission: SetupPermission.ManageWallets,
    },
    SyncCollection: {
      permission: SetupPermission.ManageWallets,
    },
    RemoveCollection: {
      permission: SetupPermission.ManageWallets,
    },
    EditCollection: {
      permission: PaymentsPermission.CreateBills,
    },
  },

  Reports: {
    RequestedReports: { permission: BookkeepingPermission.DownloadReports },
    ClosingPositions: { Export: { permission: BookkeepingPermission.DownloadReports } },
    RealizedGainLoss: { Export: { permission: BookkeepingPermission.DownloadReports } },
    CryptoProfitAndLoss: { Export: { permission: BookkeepingPermission.DownloadReports } },
    WalletAndTokenBalances: { Export: { permission: BookkeepingPermission.DownloadReports } },
    AccountTransactions: { Export: { permission: BookkeepingPermission.DownloadReports } },
    ScheduleOfDispositions: { Export: { permission: BookkeepingPermission.DownloadReports } },
    CostBasisReport: { Export: { permission: BookkeepingPermission.DownloadReports } },
    RoyaltyFees: { Export: { permission: BookkeepingPermission.DownloadReports } },
    FasbRollForwardReport: { Export: { permission: BookkeepingPermission.DownloadReports } },
  },

  TaxLots: {
    AddContact: {
      permission: BookkeepingPermission.ManageContacts,
    },
    EditMemo: {
      permission: BookkeepingPermission.ManageContacts,
    },
    AddWallet: {
      permission: SetupPermission.ManageWallets,
    },
  },

  Policies: {
    Impairment: {
      ToggleEnabledState: {
        permission: BookkeepingPermission.SyncToERP,
      },
      SelectFrequency: {
        permission: BookkeepingPermission.SyncToERP,
      },
      EditInitiatorPercent: {
        permission: BookkeepingPermission.SyncToERP,
      },
      SelectAssets: {
        permission: BookkeepingPermission.SyncToERP,
      },
    },
    DeFi: {
      SelectDefiProtocolSource: { permission: BookkeepingPermission.EditTransactions },
    },
  },

  Settings: {
    Organization: {
      EditImage: {
        permission: OrganizationsPermission.ManageOrganizations,
      },
      DeleteImage: {
        permission: OrganizationsPermission.ManageOrganizations,
      },
      EditDetails: {
        permission: OrganizationsPermission.ManageOrganizations,
      },
      InviteMember: {
        permission: OrganizationsPermission.ManageUsers,
      },
      DeleteMember: {
        permission: OrganizationsPermission.ManageUsers,
      },
      EditMemberRole: {
        permission: OrganizationsPermission.AssignUserRoles,
      },
      ToggleAIEnhancedFeatures: {
        permission: OrganizationsPermission.ManageOrganizations,
      },
      ToggleBKV2: {
        permission: OrganizationsPermission.ManageOrganizations,
      },
      DeleteOrganization: {
        permission: OrganizationsPermission.ManageOrganizations,
      },
    },

    Integrations: {
      ManageERP: {
        ConnectToERP: {
          permission: SetupPermission.ManageERP,
        },
        RefreshERP: {
          permission: SetupPermission.ManageERP,
        },
        DeleteERP: {
          permission: SetupPermission.ManageERP,
        },
      },
    },
  },

  GettingStarted: {
    ImportCryptoTransactions: { permission: SetupPermission.ManageWallets },
    ImportChartOfAccounts: { permission: SetupPermission.ManageERP },
    MapChartOfAccounts: { permission: BookkeepingPermission.EditTransactions },
    IntroductionToBookkeeping: { permission: BookkeepingPermission.EditTransactions },
  },
} as const;
