// React
import React from 'react';

// Internal
import { SASS_VARIABLES } from '../utils/styles';

// Antd
import { Typography as AntdTypography } from 'antd';
import { TextProps as AntdTextProps } from 'antd/es/typography/Text';
import { TitleProps as AntdTitleProps } from 'antd/es/typography/Title';

// Styles
import styles from './Typography.module.scss';

// Misc
import classNames from 'classnames';
import { omit } from 'lodash-es';

export type EditableProps = {
  onlyRevealsOnHover?: boolean;
  rightAligned?: boolean;
};

interface BaseTypographyProps {
  editableProps?: EditableProps;
}

export interface TitleProps extends AntdTitleProps, BaseTypographyProps {
  color?: 'primary' | 'secondary' | 'tertiary' | 'quarternary';
  inline?: boolean;
}

const getHeaderFontWeight = (level: AntdTitleProps['level']) => {
  if (level === 3) {
    return 500;
  }

  return SASS_VARIABLES.fontWeightHeaders;
};

export function Title(props: TitleProps) {
  const { editableProps } = props;

  const fontWeight = getHeaderFontWeight(props.level);

  return (
    <AntdTypography.Title
      {...omit(props, 'inline')}
      className={classNames(
        {
          'ant-typography--edit-only-reveals-on-hover': editableProps?.onlyRevealsOnHover,
          'ant-typography--edit-right-aligned': editableProps?.rightAligned,
        },
        props.className,
      )}
      style={{
        color: props.type
          ? undefined
          : props.color
            ? ({
                primary: SASS_VARIABLES.colorPrimary,
                secondary: SASS_VARIABLES.colorTextSecondary,
                tertiary: SASS_VARIABLES.colorTextTertiary,
                quarternary: SASS_VARIABLES.colorTextQuaternary,
              }[props.color] as string)
            : (SASS_VARIABLES.colorTextSecondary as string),
        display: props.inline ? 'inline' : 'block',
        textTransform: props.level === 4 ? 'uppercase' : undefined,
        fontWeight,
        wordBreak: 'normal',
        margin: 0,
        ...props.style,
      }}
    />
  );
}

export interface TextProps extends AntdTextProps, BaseTypographyProps {
  color?: 'primary' | 'secondary' | 'tertiary' | 'quarternary' | 'white';
  inline?: boolean;
  size?: 'xsmall' | 'small' | 'default' | 'large';
}

export function Text(props: TextProps) {
  const { editableProps } = props;

  return (
    <AntdTypography.Text
      {...omit(props, 'inline')}
      className={classNames(
        {
          'ant-typography--edit-only-reveals-on-hover': editableProps?.onlyRevealsOnHover,
          'ant-typography--edit-right-aligned': editableProps?.rightAligned,
        },
        props.className,
      )}
      style={{
        color: props.type
          ? undefined
          : props.color
            ? ({
                primary: SASS_VARIABLES.colorPrimary,
                secondary: SASS_VARIABLES.colorTextSecondary,
                tertiary: SASS_VARIABLES.colorTextTertiary,
                quarternary: SASS_VARIABLES.colorTextQuaternary,
                white: '#fff',
              }[props.color] as string)
            : (SASS_VARIABLES.colorTextSecondary as string),
        display: props.inline ? 'inline' : 'block',
        fontWeight: props.strong ? SASS_VARIABLES.fontWeightStrong : SASS_VARIABLES.fontWeight,
        fontSize: props.size
          ? {
              xsmall: SASS_VARIABLES.fontSizeXS,
              small: SASS_VARIABLES.fontSizeSM,
              default: SASS_VARIABLES.fontSize,
              large: SASS_VARIABLES.fontSizeLG,
            }[props.size]
          : undefined,
        wordBreak: 'normal',
        margin: 0,
        ...props.style,
      }}
    />
  );
}

interface LabelProps {
  children: React.ReactNode;
  htmlFor?: string;
  inline?: boolean;
  style?: React.CSSProperties;
}

export function Label({ children, inline, htmlFor, style }: LabelProps) {
  return (
    <label
      htmlFor={htmlFor}
      className={styles.label}
      style={{
        color: SASS_VARIABLES.colorTextTertiary as string,
        lineHeight: 1.5,
        display: inline ? 'inline' : 'block',
        ...style,
      }}
    >
      {children}
    </label>
  );
}

const exports = {
  Label,
  Text,
  Title,
};

export default exports;
