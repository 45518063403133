import React, { useEffect, useState } from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';

// Internal
import Typography from '../../components/Typography';
import ForgotPassword from './forgot-password';
import { MfaVerification } from './MfaVerification';

// Firebase
import { AuthErrorCodes, getAuth, getMultiFactorResolver } from 'firebase/auth';
import { auth, signInWithGoogle, signInWithEmailAndPassword } from '../../services/firebase';
import Register from './register';

// Antd
import { Form, Input, Button, Space, message, Spin } from 'antd';

// Icons
import { Email, Password } from '@carbon/icons-react';

// Styles
import styles from './login.module.scss';

// Images
import LOGO from '../../img/logo/fill_primary.svg';
import GOOGLE from '../../img/pages/login/google.svg';
// import GITHUB from '../../img/pages/login/github.svg';
// import TWITTER from '../../img/pages/login/twitter.svg';

// MISC
import { useAuthState } from 'react-firebase-hooks/auth';
import XeroLeadForm from '../xeroLeadForm';

let resolver: any = null;

export enum LoginFormTypes {
  SIGN_IN_FROM_SCRATCH = 'SIGN_IN_FROM_SCRATCH',
  RE_SIGN_IN = 'RE_SIGN_IN',
  GOOGLE_POPUP = 'GOOGLE_POPUP',
}
interface LoginProps {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  type: LoginFormTypes;
  onSuccessfulLogin?: Function;
}
export const Login = ({ header, footer, type, onSuccessfulLogin }: LoginProps) => {
  const [mfaStep, setMfaStep] = useState<number>(0);

  const onFinish = (values: any) => {
    signInWithEmailAndPassword(values.email, values.password)
      .then(() => {
        if (onSuccessfulLogin) {
          onSuccessfulLogin();
        } else {
          message.success('Welcome back!');
        }
      })
      .catch((error) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          message.error(
            'An account with this email address exists through a different login method',
          );

          return;
        } else if (error.code === AuthErrorCodes.MFA_REQUIRED) {
          // The user is a multi-factor user. Second factor challenge is required.
          resolver = getMultiFactorResolver(getAuth(), error);

          setMfaStep(1);

          return;
        }

        message.error('Something went wrong. Please try again.');
      });
  };

  const handleGoogleSignIn = () => {
    signInWithGoogle()
      .then(() => {
        if (onSuccessfulLogin) {
          onSuccessfulLogin();
        } else {
          message.success('Welcome back!');
        }
      })
      .catch((error) => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          message.error(
            'An account with this email address exists through a different login method',
          );

          return;
        } else if (error.code === 'auth/popup-blocked') {
          message.warning('Login popup window might be getting blocked by browser');

          return;
        } else if (error.code === AuthErrorCodes.MFA_REQUIRED) {
          // The user is a multi-factor user. Second factor challenge is required.
          resolver = getMultiFactorResolver(getAuth(), error);

          setMfaStep(1);

          return;
        }

        message.error('Something went wrong. Please try again.');
      });
  };

  const [user] = useAuthState(auth);
  const [reSignInForm] = Form.useForm();
  if (user) {
    reSignInForm.setFieldsValue({
      email: user.email,
    });
  }

  useEffect(() => {
    if (type === LoginFormTypes.GOOGLE_POPUP) {
      handleGoogleSignIn();
    }
  }, []);

  if (mfaStep !== 0) {
    return (
      <MfaVerification
        mfaStep={mfaStep}
        setMfaStep={setMfaStep}
        resolver={resolver}
        onSuccessfulLogin={onSuccessfulLogin}
      />
    );
  }

  if (type === LoginFormTypes.GOOGLE_POPUP) {
    return (
      <Spin spinning={true} tip="Sigining-in with Google">
        <div style={{ height: '3rem' }}></div>
      </Spin>
    );
  }

  return (
    <>
      <Space direction="vertical" className={styles.loginPage__container__form__methods}>
        {header}

        {type === LoginFormTypes.SIGN_IN_FROM_SCRATCH && (
          <Form onFinish={onFinish} style={{ marginTop: 16 }}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Missing email address' },
                { type: 'email', message: 'Invalid email address' },
              ]}
              style={{ marginBottom: '0', paddingBottom: '1rem' }}
            >
              <Input autoFocus prefix={<Email />} placeholder="Enter your email" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Missing password' }]}
              style={{ marginBottom: '0', paddingBottom: '1rem' }}
            >
              <Input prefix={<Password />} type="password" placeholder="Enter your password" />
            </Form.Item>

            <Form.Item>
              <Link to="/forgot-password">Forgot password</Link>
            </Form.Item>

            <Button type="primary" htmlType="submit" style={{ marginTop: '1rem' }}>
              Log In
            </Button>

            <Button onClick={handleGoogleSignIn} style={{ marginTop: '1rem' }}>
              <img src={GOOGLE} alt="Google" /> Sign In with Google
            </Button>
          </Form>
        )}

        {type === LoginFormTypes.RE_SIGN_IN && (
          <Form onFinish={onFinish} form={reSignInForm}>
            <Form.Item name="email">
              <Input prefix={<Email />} placeholder="Email address" disabled={true} />
            </Form.Item>

            <Form.Item name="password" rules={[{ required: true, message: 'Missing password' }]}>
              <Input prefix={<Password />} type="password" placeholder="Password" />
            </Form.Item>

            <Button type="primary" htmlType="submit">
              Log In
            </Button>
          </Form>
        )}

        {footer}
      </Space>
    </>
  );
};

export default function LoginPage() {
  // Define the enums for login states
  const LoginStates = {
    NONE: 'NONE',
    PASSWORD_RESET: 'PASSWORD_RESET',
    INVITE: 'INVITE',
  };

  const loginTitleText = {
    [LoginStates.NONE]: (
      <>
        <Typography.Title level={1}>Welcome back</Typography.Title>
        <Typography.Text color="tertiary">Please enter your details to log back in</Typography.Text>
      </>
    ),
    [LoginStates.PASSWORD_RESET]: (
      <>
        <Typography.Title type="success">You reset your password</Typography.Title>
        <Typography.Text color="tertiary">Please enter your details to log back in</Typography.Text>
      </>
    ),
    [LoginStates.INVITE]: (
      <>
        <Typography.Title>You already have an account</Typography.Title>
        <Typography.Text color="tertiary">Please enter your details to log back in</Typography.Text>
      </>
    ),
  };

  const location = useLocation();
  const [loginState, setLoginState] = useState(LoginStates.NONE);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const isPwdReset = params.get('reset') === 'true';
    const isInvite = params.get('invite') === 'true';
    if (isPwdReset) {
      setLoginState(LoginStates.PASSWORD_RESET);
    } else if (isInvite) {
      setLoginState(LoginStates.INVITE);
    } else {
      setLoginState(LoginStates.NONE);
    }
  }, []);

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginPage__bg}>
        <div className={styles.loginPage__bg__glow1}></div>
        <div className={styles.loginPage__bg__glow2}></div>
      </div>

      <div className={styles.loginPage__container}>
        <div className={styles.loginPage__logo}>
          <img src={LOGO} alt="Integral" />
        </div>

        <div className={[styles.loginPage__section, styles.loginPage__container__form].join(' ')}>
          <Routes>
            <Route
              path="/"
              element={
                <Login
                  header={loginTitleText[loginState] ?? undefined}
                  footer={
                    <div className={styles.loginPage__container__form__signup__message}>
                      <Typography.Text color="tertiary">
                        Don't have an account? <Link to="/register">Sign up</Link>
                      </Typography.Text>
                    </div>
                  }
                  type={LoginFormTypes.SIGN_IN_FROM_SCRATCH}
                />
              }
            />
            <Route path="/xero-signup" element={<XeroLeadForm />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
