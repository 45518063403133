import { ReactNode, createContext, useReducer } from 'react';
// hooks
import {
  getTransactionTags,
  TagsDto,
  deleteTransactionTag,
  updateTransactionTag,
} from '@/api/transactions';

import { message } from 'antd';

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type TagsContextType = {
  tags: TagsDto[];
  deletedIds: Set<string>;
  getAllTags: Function;
  addNewTags: Function;
  deleteTag: Function;
  updateTag: Function;
};

export type TagsContextProps = {
  tags: TagsDto[];
  deletedIds: Set<string>;
};

const initialState: TagsContextProps = {
  tags: [],
  deletedIds: new Set([]),
};

enum Types {
  Tags = 'getTags',
  addTags = 'addTags',
  deleteTag = 'deleteTag',
  updateTag = 'updateTag',
}

type TagsPayload = {
  [Types.Tags]: {
    tags: TagsDto[];
  };
  [Types.addTags]: {
    newTags: any;
  };
  [Types.deleteTag]: {
    id: string;
  };
  [Types.updateTag]: {
    updatedTags: TagsDto[];
  };
};

type TagsActions = ActionMap<TagsPayload>[keyof ActionMap<TagsPayload>];

const TagsContext = createContext<TagsContextType | null>(null);

const reducer = (state: TagsContextProps, action: TagsActions) => {
  switch (action.type) {
    case Types.Tags:
      const { tags } = action.payload;
      return {
        ...state,
        tags,
      };
    case Types.addTags:
      const { newTags } = action.payload;
      return { ...state, tags: [...state.tags, newTags] };
    case Types.updateTag:
      const { updatedTags } = action.payload;
      return { ...state, tags: updatedTags };
    case Types.deleteTag:
      const { id } = action.payload;
      return {
        ...state,
        tags: state.tags.filter((item) => item.id !== id),
        deletedId: state.deletedIds.add(id),
      };
    default:
      return state;
  }
};

type TagsProps = {
  children: ReactNode;
};

function TagsProvider({ children }: TagsProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getAllTags = () => {
    getTransactionTags()
      .then(({ body }) => {
        dispatch({
          type: Types.Tags,
          payload: { tags: body.data || null },
        });
      })
      .catch((err) => {
        message.error('Something went wrong');
      });
  };

  const addNewTags = (tag: any) => {
    dispatch({
      type: Types.addTags,
      payload: { newTags: tag || null },
    });
  };

  const updateTag = (tag: string, newName: string, getTableData: Function) => {
    const result = state.tags.filter((a) => a.id === tag)[0] as TagsDto;
    updateTransactionTag(result.id as string, { name: newName })
      .then(async ({ body }) => {
        const result = state.tags.filter((a) => a.id !== tag);
        const newTagsArray = [...result, body.data] as TagsDto[];
        dispatch({
          type: Types.updateTag,
          payload: { updatedTags: newTagsArray },
        });
      })
      .catch((err) => {
        message.error('Something went wrong');
      });
  };

  const deleteTag = (tag: string, getTableData: Function) => {
    deleteTransactionTag(tag)
      .then(async () => {
        dispatch({
          type: Types.deleteTag,
          payload: { id: tag },
        });
      })
      .catch((err) => {
        message.error('Something went wrong');
      });
  };

  return (
    <TagsContext.Provider
      value={{
        ...state,
        getAllTags,
        addNewTags,
        deleteTag,
        updateTag,
      }}
    >
      {children}
    </TagsContext.Provider>
  );
}

export { TagsProvider, TagsContext };
