// React
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

// Internal
import { SESSION_KEYS } from '@/utils/constants';
import { BookAccountsProvider } from './contexts/BookAccountsContext';
import { EnabledFeaturesProvider } from './contexts/EnabledFeatures';
import { OnboardConnectionDetailsProvider } from './contexts/OnboardConnectionContext';
import { OrganizationsProvider } from './contexts/OrganizationsContext';
import { VestingContractsProvider } from './contexts/VestingContractsContext';
import { WalletsAssetsProvider } from './contexts/WalletsAssetsContext';
import useAppLoad from './hooks/useAppLoad';
import CreateAccount from './pages/users/create-account';
import Login from './pages/users/login';
import ResetPassword from './pages/users/reset-password';
import Router from './routes';

// Antd
import { LoadingOutlined } from '@ant-design/icons';
import { App as AntdApp, Spin } from 'antd';

// Styles
import styles from './App.module.scss';
import './pages/transactions/transactions.scss';
import AccountSyncingProvider from './contexts/AccountsSyncingContext';

// Antd defaults
const ANTD_APP_CONFIGS = {
  message: {
    maxCount: 1,
  },
};

// Custom loading spinner
Spin.setDefaultIndicator(<LoadingOutlined spin />);

function App() {
  const { user, appLoading, organization, organizations, setOrganization } = useAppLoad();

  /**
   * `currentOrgFromSessionStorage` is `undefined` if user is logged out
   * `currentOrgFromSessionStorage` is `null` if user explicitly removes `currentOrg` from Session Storage
   */
  const [currentOrgFromSessionStorage, setCurrentOrgFromSessionStorage] = useState<
    string | undefined | null
  >(sessionStorage.getItem(SESSION_KEYS.currentOrg) ?? undefined);

  // Trigger an App re-render whenever `currentOrg` is explicitly removed from Session Storage
  useEffect(() => {
    const handleStorageChange = () => {
      if (sessionStorage.getItem(SESSION_KEYS.currentOrg) === null) {
        setCurrentOrgFromSessionStorage(null);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  /**
   * Refresh the page whenever the user's `currentOrg` is removed from Session Storage:
   * gauruntees that `currentOrg` will always be present for a user's session;
   * gauruntees `x-org-id` header will always be present in all requests;
   */
  useEffect(() => {
    if (user && !appLoading && currentOrgFromSessionStorage === null) {
      window.location.reload();
    }
  }, [user, appLoading, currentOrgFromSessionStorage]);

  if (appLoading) {
    return <Spin className={styles.loading} />;
  } else if (user && organizations && organization) {
    return (
      <AntdApp {...ANTD_APP_CONFIGS}>
        <OrganizationsProvider
          organizations={organizations}
          organization={organization}
          setOrganization={setOrganization}
        >
          <EnabledFeaturesProvider>
            <OnboardConnectionDetailsProvider>
              <BookAccountsProvider>
                <WalletsAssetsProvider>
                  <VestingContractsProvider>
                    <AccountSyncingProvider>
                      <Router />
                    </AccountSyncingProvider>
                  </VestingContractsProvider>
                </WalletsAssetsProvider>
              </BookAccountsProvider>
            </OnboardConnectionDetailsProvider>
          </EnabledFeaturesProvider>
        </OrganizationsProvider>
      </AntdApp>
    );
  } else {
    return (
      <AntdApp {...ANTD_APP_CONFIGS}>
        <Routes>
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="create-account" element={<CreateAccount />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </AntdApp>
    );
  }
}

export default App;
