import { useNavigate } from 'react-router-dom';

// Internal
import Typography, { Label } from '../../components/Typography';
import {
  confirmPasswordReset,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from '../../services/firebase';

// Antd
import { Form, Input, Button, Space, message } from 'antd';

// Styles
import styles from './login.module.scss';
import React, { useEffect, useState } from 'react';
import LOGO from '../../img/logo/fill_primary.svg';
import { resendInvite } from '@/api/invite';

export default function CreateAccount() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [orgName, setOrgName] = useState('');
  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const oobCode = searchParams.get('code');
    const email = searchParams.get('email');
    const orgName = searchParams.get('orgName');

    if (oobCode) {
      setCode(oobCode);
      verifyPasswordResetCode(oobCode)
        .then((result) => {
          form.setFieldsValue({
            email: result,
          });
        })
        .catch((error) => {
          console.error('Error verifying password reset code:', error);
          setExpired(true);
        });
    } else {
      // oobCode is mandatory, redirect if not present
      navigate('/');
    }

    if (email) {
      setEmail(email);
    } else {
      // email is mandatory, redirect if not present
      navigate('/');
    }

    if (orgName) {
      setOrgName(orgName);
    } else {
      // orgName is mandatory, redirect if not present
      navigate('/');
    }
  }, []);

  const onFinish = (values: any) => {
    setLoading(true);
    confirmPasswordReset(code, values.password)
      .then(() => {
        // Auto login the user after account is successfully created
        signInWithEmailAndPassword(values.email, values.password)
          .then(() => {
            message.success('Successfully created!');
            navigate('/');
          })
          .catch(() => {
            message.error('Something went wrong. Please try again.');
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        console.error('Error creating account:', error);
        message.error('Something went wrong. Please try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRequestNewLinkFinish = () => {
    setLoading(true);
    resendInvite({ email: email, orgName: orgName })
      .then(() => {
        message.success('New link requested!');
      })
      .catch((error) => {
        console.error('Error inviting new member:', error);
        message.error('Something went wrong. Please try again.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Space direction="vertical" className={styles.loginPage__container__form__methods}>
        <div className={styles.loginPage}>
          <div className={styles.loginPage__bg}>
            <div className={styles.loginPage__bg__glow1}></div>
            <div className={styles.loginPage__bg__glow2}></div>
          </div>

          <div className={styles.loginPage__container}>
            <div className={styles.loginPage__logo}>
              <img src={LOGO} alt="Integral" />
            </div>

            <div
              className={[styles.loginPage__section, styles.loginPage__container__form].join(' ')}
              style={{ padding: '3.5rem 4rem' }}
            >
              {!expired && (
                <>
                  <Typography.Title style={{ marginBottom: '1rem' }}>
                    Create Your Account
                  </Typography.Title>
                  <Typography.Text color="tertiary" style={{ marginBottom: '2rem' }}>
                    You've been invited to the {orgName} organization on Integral
                  </Typography.Text>

                  <Form form={form} onFinish={onFinish}>
                    <Label style={{ marginBottom: '6px', fontSize: '12px' }}>Email</Label>
                    <Form.Item name="email" initialValue="loading...">
                      <Input placeholder="Email address" disabled readOnly />
                    </Form.Item>

                    <Label style={{ marginBottom: '6px', fontSize: '12px' }}>Password</Label>
                    <Form.Item
                      name="password"
                      validateTrigger={'onBlur'}
                      rules={[
                        { required: true, whitespace: false, message: 'At least 12 characters' },
                        { min: 12, message: 'At least 12 characters' },
                      ]}
                      style={{ paddingBottom: '1rem', marginBottom: '0' }}
                    >
                      <Input autoFocus type="password" />
                    </Form.Item>

                    <Label style={{ marginBottom: '6px', fontSize: '12px' }}>Repeat Password</Label>
                    <Form.Item
                      name="passwordConfirmation"
                      dependencies={['password']}
                      validateTrigger={'onBlur'}
                      rules={[
                        { required: true, whitespace: false, message: `Passwords don't match` },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(`Passwords don't match`);
                          },
                        }),
                      ]}
                      style={{ paddingBottom: '1.5rem', marginBottom: '0' }}
                    >
                      <Input type="password" />
                    </Form.Item>

                    <Button type="primary" htmlType="submit" loading={loading}>
                      Create Account
                    </Button>
                  </Form>
                </>
              )}

              {expired && (
                <>
                  <Typography.Title type="warning" style={{ marginBottom: '1rem' }}>
                    This link has expired
                  </Typography.Title>
                  <Form form={form} onFinish={onRequestNewLinkFinish}>
                    <Typography.Text color="tertiary">
                      To continue, request a new link or log in to your Integral account
                    </Typography.Text>

                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      style={{ marginTop: '2rem' }}
                    >
                      Request New Link
                    </Button>

                    <Button onClick={() => navigate('/')} style={{ marginTop: '1rem' }}>
                      Log In
                    </Button>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
      </Space>
    </>
  );
}
