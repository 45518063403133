import { auth } from '../services/firebase';
import { IOrganization } from './organization';

enum AnalyticsEventName {
  // Page view
  NftInventoryView = 'NftInventoryView',
  NftCollectionView = 'NftCollectionView',
  TreasuryView = 'TreasuryView',
  WalletView = 'WalletView',
  ContactView = 'ContactView',
  BookkeepingView = 'BookkeepingView',
  BookkeepingV2View = 'BookkeepingV2View',
  RulesView = 'RulesView',
  ImpairmentView = 'ImpairmentView',
  ReportView = 'ReportView',
  TaxLotView = 'TaxLotView',
  AssetView = 'AssetView',
  BillView = 'BillView',
  AssetsFiatView = 'AssetsFiatView',

  // Actions
  AccountingSoftwareConnect = 'AccountingSoftwareConnect',
  JournalSync = 'JournalSync',
  ReportExport = 'ReportExport',
  RuleCreate = 'RuleCreate',
  ImpairmentCreate = 'ImpairmentCreate',
  WalletCreate = 'WalletCreate',
  BeneficiaryUpsert = 'BeneficiaryUpsert',
  BillCreate = 'BillCreate',
  BillReconcile = 'BillReconcile',
  AISearch = 'AISearch',
  DeFiHistoricalFilter = 'DeFiHistoricalFilter',
  OrganizationCreate = 'OrganizationCreate',
  // Fiat/Plaid actions
  FiatPlaidWalletAdded = 'FiatPlaidWalletAdded',

  // BookkeepingV2
  Bkv2CategorizeTxn = 'Bkv2CategorizeTxn',
  Bkv2MarkTxnSpam = 'Bkv2MarkTxnSpam',
  Bkv2ManuallyGroupTxn = 'Bkv2ManuallyGroupTxn',
  Bkv2MoveTxnToReview = 'Bkv2MoveTxnToReview',
  Bkv2Sync = 'Bkv2Sync',
  Bkv2ExportJe = 'Bkv2ExportJe',
  Bkv2TxnBulkEdit = 'Bkv2TxnBulkEdit',
  Bkv2OrgSwitch = 'Bkv2OrgSwitch',
}

const INTERNAL_EMAIL_DOMAINS = ['integral.xyz', 'asgardtreasury.xyz'];

class Analytics {
  private organization?: IOrganization;

  constructor() {
    this.organization = undefined;
  }

  identify(userId: string, email: string, type?: string, accountingFirm?: string) {
    if (
      import.meta.env.VITE_ANALYTICS_FILTER_INTERNAL_TRAFFIC === 'true' &&
      INTERNAL_EMAIL_DOMAINS.some((domain) => email.includes(domain))
    ) {
      return;
    }

    if (import.meta.env.VITE_ANALYTICS_ENABLED === 'true') {
      analytics.identify(userId, { email, type, accountingFirm });
    }
  }

  track(event: AnalyticsEventName, properties?: Record<string, string | boolean | number | null>) {
    const email = auth.currentUser?.email ?? '';
    if (
      import.meta.env.VITE_ANALYTICS_FILTER_INTERNAL_TRAFFIC === 'true' &&
      INTERNAL_EMAIL_DOMAINS.some((domain) => email.includes(domain))
    ) {
      return;
    }

    if (import.meta.env.VITE_ANALYTICS_ENABLED === 'true') {
      const context = this.organization?.id ? { groupId: this.organization.id } : {};
      analytics.track(event, properties, { context });
    }
  }

  group(organization: IOrganization) {
    this.organization = organization;
    const email = auth.currentUser?.email ?? '';
    if (
      import.meta.env.VITE_ANALYTICS_FILTER_INTERNAL_TRAFFIC === 'true' &&
      INTERNAL_EMAIL_DOMAINS.some((domain) => email.includes(domain))
    ) {
      return;
    }

    if (import.meta.env.VITE_ANALYTICS_ENABLED === 'true') {
      analytics.group(organization.id, { name: organization.name, type: organization.type });
    }
  }
}

const analyticsTracking = new Analytics();

export { analyticsTracking, AnalyticsEventName };
