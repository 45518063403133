// Internal
import { app as firebaseApp } from '../services/firebase';
import { SESSION_KEYS, STORAGE_KEYS } from '../utils/constants';

// MISC
import { getAuth } from 'firebase/auth';
import queryString from 'query-string';
import { get } from 'lodash-es';
import contentType from 'content-type';

export const httpCodes = {
  CONTINUE: 100,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TIMEOUT: 408,
  RESOURCE_CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
};

const auth = getAuth(firebaseApp);
const RESPONSE_TIMEOUT = 600000; // 10 minute timeout

export interface ApiResponse<T> {
  data: T;
  meta: {
    workflowId?: string;
    txnCount?: number;
    count?: number; // when backend is able to determine the number of entities
    hasMore?: boolean; // when backend is unable to determine the number of entities
    nextPage?: string /** @deprecated use `hasMore` instead */;
    errors?: any[];
    currentPage?: number;
    totalPages?: number;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
  };
}

export interface QueryParams {
  [key: string]: string | boolean | number;
}

export interface ApiMessage {
  message: string;
  code: number;
}

export class ApiError extends Error {
  public readonly code: string;
  public readonly statusCode: number;
  public readonly isClientError: boolean;
  public readonly param?: any;
  public readonly body?: any;

  constructor(message: string, code: string, statusCode: number, param?: any, body?: any) {
    super(message);
    this.name = 'ApiError';
    this.code = code;
    this.statusCode = statusCode;
    this.isClientError = statusCode >= 400 && statusCode <= 499;
    this.param = param;

    if (body) {
      this.body = body;
    }
  }
}

export const apiRequest = async <T>(url: string, opts: any) => {
  // If the request does not have an abort controller attached, create a new one and attach it
  let timeout;
  if (!opts.signal) {
    const controller = new AbortController();
    const { signal } = controller;
    opts.signal = signal;

    // Set timeout for fetch request
    timeout = window.setTimeout(() => {
      controller.abort();
    }, RESPONSE_TIMEOUT);
  }
  let options = opts;

  const accessToken = auth.currentUser ? await auth.currentUser.getIdToken() : undefined;
  if (accessToken) {
    options = {
      ...opts,
      headers: {
        ...opts.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };

    if (window.sessionStorage) {
      const currentOrgId = window.sessionStorage.getItem(SESSION_KEYS.currentOrg);

      if (currentOrgId) {
        if (window.localStorage) {
          const email = auth.currentUser?.email ?? '';
          const env = import.meta.env.VITE_API_URL;
          const lastOrgKey = `${STORAGE_KEYS.lastOrg}-${env}-${email}`;
          window.localStorage.setItem(lastOrgKey, currentOrgId);
        }

        options.headers['X-org-id'] = currentOrgId;
      }
    }
  }

  try {
    const res = await window.fetch(url, options);
    clearTimeout(timeout);

    // either json, blob or text on success
    if (res.ok) {
      if (res.status === 204) {
        return { res, body: { data: null } };
      }
      const responseType =
        contentType.parse(res?.headers?.get('content-type') || '').type || 'application/json';
      switch (responseType) {
        case 'application/json':
          return { res, body: await res.json(), responseType };
        case 'text/csv':
          return { res, body: await res.text(), responseType };
        default:
          return { res, body: await res.blob(), responseType };
      }
    }

    // on error status, try to parse JSON response even if blob was requested
    const body = await res.json();

    // TODO: handle different error responses better
    throw new ApiError(getErrorMessage(body), body.code, res.status, body.param, body);
  } catch (error) {
    clearTimeout(timeout);
    let errName;
    if (error instanceof Error) errName = error.name;
    if (errName === 'AbortError' || error instanceof DOMException) {
      throw new ApiError('Request timed out', 'timeout', httpCodes.TIMEOUT);
    }
    throw error;
  }
};

function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return get(error, 'message') || String(error);
}

const postJsonOptions = function (data: any, headers = {}, opts = {}) {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...headers,
    },
    body: JSON.stringify(data),
    ...opts,
  };
};

const postMultiPartFormOptions = function (data: any, headers = {}, opts = {}) {
  return {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      ...headers,
    },
    body: data,
    ...opts,
  };
};

export const getRequest = <T>(endpoint: string, params: any, headers = {}, opts = {}) => {
  let queryStr = '';
  if (params && Object.keys(params).length > 0) {
    queryStr = `?${queryString.stringify(params, { arrayFormat: 'comma', encode: false })}`;
  }
  const url = import.meta.env.VITE_API_URL + endpoint + queryStr;
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      ...headers,
    },
    ...opts,
  };
  return apiRequest<T>(url, options);
};

export const patchRequest = <T>(endpoint: string, data: any, headers = {}, opts = {}) => {
  const url = import.meta.env.VITE_API_URL + endpoint;
  const options = postJsonOptions(data, headers, opts);
  options.method = 'PATCH';
  return apiRequest<T>(url, options);
};

export const patchQueryRequest = <T>(endpoint: string, params: any, headers = {}, opts = {}) => {
  let queryStr = '';
  if (params && Object.keys(params).length > 0) {
    queryStr = `?${queryString.stringify(params, { arrayFormat: 'comma', encode: false })}`;
  }
  const url = import.meta.env.VITE_API_URL + endpoint + queryStr;
  const options = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      ...headers,
    },
    ...opts,
  };
  return apiRequest<T>(url, options);
};

export const postRequest = <T>(endpoint: string, data: any, headers = {}, opts = {}) => {
  const url = import.meta.env.VITE_API_URL + endpoint;
  return apiRequest<T>(url, postJsonOptions(data, headers, opts));
};

export const postMultipartFormRequest = <T>(
  endpoint: string,
  data: any,
  headers = {},
  opts = {},
) => {
  const url = import.meta.env.VITE_API_URL + endpoint;
  return apiRequest<T>(url, postMultiPartFormOptions(data, headers, opts));
};

export const putRequest = <T>(endpoint: string, data: any, headers = {}, opts = {}) => {
  const url = import.meta.env.VITE_API_URL + endpoint;
  const options = postJsonOptions(data, headers, opts);
  options.method = 'PUT';
  return apiRequest<T>(url, options);
};

export const deleteRequest = <T>(endpoint: string, params?: any, headers = {}, opts = {}) => {
  let queryStr = '';
  if (params && Object.keys(params).length > 0) {
    queryStr = `?${queryString.stringify(params, { arrayFormat: 'comma', encode: false })}`;
  }
  const url = import.meta.env.VITE_API_URL + endpoint + queryStr;
  const options = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      ...headers,
    },
    ...opts,
  };
  return apiRequest<T>(url, options);
};
