// react-query
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';

// Antd
import { message } from 'antd';

// Internal
import {
  Account,
  AccountName,
  AccountSection,
  BalanceCheck,
  BalanceCheckSnapshotGroup,
  BalanceOverTime,
  BulkDeleteAccountDto,
  BulkUpdateAccountDto,
  BulkUpdateAccountSectionDto,
  CreateAccountSectionDto,
  DefiBalancesCountDto,
  DefiBalancesForProtocol,
  DefiBalancesQuery,
  FiatBalancesForCurrency,
  FiatBalancesForSource,
  FiatBalancesQuery,
  GetAccountsQuery,
  GetAccountsWalletBalancesDto,
  GetBalancesOvertimeDto,
  ICreateAccountPayload,
  IncludeArchiveSectionsDto,
  TokenBalanceByDayDto,
  TokenBalanceDto,
  TokenBalancesQuery,
  TokensBalanceDto,
  UpdateAccountDto,
  UpdateAccountSectionDto,
  WalletBalanceDto,
  WalletByDayDto,
  WalletChainsDto,
  AccountWithChildren,
} from './__types/accounts.types';
import { AnalyticsEventName, analyticsTracking } from './analytics';
import { ApiResponse, deleteRequest, getRequest, patchRequest, postRequest } from './request';

// Misc
import { get } from 'lodash-es';
import { AccountWithDto } from '@/pages/accounts/wallets/__components/WalletsGroupedBySource';

// Export types
export * from './__types/accounts.types';

// Query keys
export const accountsQueryKeys = {
  all: ['accounts'] as const,
  lists: () => [...accountsQueryKeys.all, 'list'] as const,
  list: (dto?: GetAccountsQuery) => [...accountsQueryKeys.lists(), dto] as const,
  sections: () => [...accountsQueryKeys.all, 'section'] as const,

  accountsWalletBalancesList: () => [...accountsQueryKeys.all, 'accountsWalletBalances'] as const,
  accountsWalletBalances: (dto: GetAccountsWalletBalancesDto) =>
    [...accountsQueryKeys.accountsWalletBalancesList(), dto] as const,

  // Balances over time
  balancesOverTimeList: () => [...accountsQueryKeys.all, 'balancesOverTime'] as const,
  balancesOverTime: (dto: GetBalancesOvertimeDto) =>
    [...accountsQueryKeys.balancesOverTimeList(), dto] as const,

  // Account wallet chains
  walletChainsList: () => [...accountsQueryKeys.all, 'walletChains'] as const,

  // DeFi
  defiBalancesList: () => [...accountsQueryKeys.all, 'defiBalance'] as const,
  defiBalances: (dto: DefiBalancesQuery) => [...accountsQueryKeys.defiBalancesList(), dto] as const,
  defiBalancesCountList: () => [...accountsQueryKeys.all, 'defiBalancesCount'] as const,
  defiBalancesCount: (dto: DefiBalancesQuery) =>
    [...accountsQueryKeys.defiBalancesCountList(), dto] as const,

  tokenBalancesLists: () => [...accountsQueryKeys.all, 'tokenBalancesLists'] as const,
  tokenBalancesList: (dto: TokenBalancesQuery) =>
    [...accountsQueryKeys.tokenBalancesLists(), dto] as const,
  tokenBalances: (dto: TokenBalancesQuery) =>
    [...accountsQueryKeys.tokenBalancesList(dto), 'tokenBalances'] as const,

  walletBalancesLists: () => [...accountsQueryKeys.all, 'walletBalancesLists'] as const,
  walletBalancesList: (dto: TokenBalancesQuery) =>
    [...accountsQueryKeys.walletBalancesLists(), dto] as const,
  walletBalances: (dto: TokenBalancesQuery) =>
    [...accountsQueryKeys.walletBalancesList(dto), 'walletBalance'] as const,

  // Fiat
  fiatBalances: () => [...accountsQueryKeys.all, 'fiat-Balances'] as const,
  allFiatBalancesByCurrency: () => [...accountsQueryKeys.fiatBalances(), 'by-currency'] as const,
  fiatBalancesByCurrency: (dto: FiatBalancesQuery) =>
    [...accountsQueryKeys.allFiatBalancesByCurrency(), dto] as const,
  allFiatBalancesBySource: () => [...accountsQueryKeys.fiatBalances(), 'by-source'] as const,
  fiatBalancesBySource: (dto: FiatBalancesQuery) =>
    [...accountsQueryKeys.allFiatBalancesBySource(), dto] as const,
};

export const createAccounts = (dto: ICreateAccountPayload[]) => postRequest('/accounts', dto);
export const useCreateAccounts = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dto: ICreateAccountPayload[]) => {
      const { res, body } = await createAccounts(dto);
      if (res.ok) {
        for (const account of dto) {
          analyticsTracking.track(AnalyticsEventName.WalletCreate, {
            type: account.type,
            provider: account.provider,
            chain: account.chain ?? null,
          });
        }
      }

      return { data: body.data, workflowId: body.meta.workflowId };
    },
    onSuccess: () => {
      // queries invalidated when new account is created to avoid duplicate additions, new account included on refetch
      queryClient.invalidateQueries(accountsQueryKeys.lists());
    },
  });
};

export const getAccounts = (dto: GetAccountsQuery = {}) =>
  getRequest<ApiResponse<Account[]>>('/accounts', dto);
export const useGetAccounts = (dto?: GetAccountsQuery) => {
  const query = useQuery<ApiResponse<Account[]>, Error>({
    queryKey: accountsQueryKeys.list(dto),
    queryFn: async () => {
      const { body } = await getAccounts(dto);

      return body;
    },
  });

  return {
    query,
    data: query.data?.data,
    loading: query.isLoading,
    meta: query.data?.meta,
  };
};

export const useGetSubAccounts = (accounts?: AccountWithDto[]) => {
  const mainAccounts = accounts?.filter((accountWithDto) => accountWithDto.account.hasSubAccounts);

  const queries: UseQueryResult<ApiResponse<AccountWithChildren>>[] = useQueries({
    queries:
      mainAccounts?.map((accountWithDto) => {
        return {
          queryKey: accountsQueryKeys.list(accountWithDto),
          queryFn: async () => {
            const { body } = await getAccounts(accountWithDto);

            body.data = { ...accountWithDto.account, accounts: body.data };

            return body;
          },
          keepPreviousData: true,
        };
      }) ?? [],
  });

  const data = queries.map((query) => query.data! ?? []);
  const isLoading = queries.some((query) => query.isLoading);
  const isFetching = queries.some((query) => query.isFetching);

  return { data, isLoading, isFetching };
};

export const updateAccounts = (dto: BulkUpdateAccountDto[]) => patchRequest('/accounts', dto);
export const useUpdateAccounts = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dto: BulkUpdateAccountDto[]) => {
      const { body } = await updateAccounts(dto);
      return body.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(accountsQueryKeys.lists());
    },
  });
};

export const updateAccount = (id: string, dto: UpdateAccountDto) =>
  patchRequest(`/accounts/${id}`, dto);
export const useUpdateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, dto }: { id: string; dto: UpdateAccountDto }) => {
      const { body } = await updateAccount(id, dto);
      return body.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(accountsQueryKeys.lists());
    },
    // If the mutation fails, don't show the error msg
    onError: (error: any) => {
      console.error(error);
    },
  });
};

export const getAccountNames = () => getRequest<ApiResponse<AccountName[]>>('/accounts/names', {});

export const deleteAccounts = (dto: BulkDeleteAccountDto) =>
  deleteRequest(
    '/accounts',
    null,
    { 'Content-Type': 'application/json' },
    { body: JSON.stringify(dto) },
  );
export const useDeleteAccounts = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dto: BulkDeleteAccountDto) => {
      const { body } = await deleteAccounts(dto);
      return body.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(accountsQueryKeys.lists());
    },
  });
};

export const deleteAccount = (accountId: string) => deleteRequest(`/accounts/${accountId}`, {});
export const useDeleteAccount = () => {
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      const { body } = await deleteAccount(id);
      return body.data;
    },
    onSuccess: () => {
      message.success('Account is being deleted… This may take up to a few minutes.');
      // TODO: backend needs to delete account faster or return a workflowId that frontend can track
    },
  });
};

export const syncAccounts = () => postRequest<ApiResponse<string>>('/accounts/sync', {});

export const syncAccount = (accountId: string) =>
  postRequest<ApiResponse<string>>(`/accounts/sync/${accountId}`, {});

export const getAccountBalances = (dto: IncludeArchiveSectionsDto) =>
  getRequest<ApiResponse<any>>('/accounts/count/balances', dto);

export const getWalletBalances = (dto: GetAccountsWalletBalancesDto) =>
  getRequest<ApiResponse<WalletBalanceDto[]>>(`/accounts/wallet-balances`, dto);
export const getWalletBalancesForTokenId = (dto: GetAccountsWalletBalancesDto) =>
  getRequest<ApiResponse<WalletBalanceDto[]>>(
    `/accounts/wallet-balances/tokens/${dto.tokenId}`,
    dto,
  );
export const useGetAccountsWalletBalances = (dto: GetAccountsWalletBalancesDto) => {
  const query = useQuery<WalletBalanceDto[], Error>({
    queryKey: accountsQueryKeys.accountsWalletBalances(dto),
    queryFn: async () => {
      const { body } = await (dto.tokenId
        ? getWalletBalancesForTokenId(dto)
        : getWalletBalances(dto));
      return body.data;
    },
  });
  return {
    query,
    data: query.data,
    loading: query.isLoading,
  };
};

export const getWalletChains = () =>
  getRequest<ApiResponse<WalletChainsDto[]>>(`/accounts/wallet-chains`, {});
export const useGetWalletChains = (dto: { enabled?: boolean }) => {
  return useQuery<WalletChainsDto[], Error>({
    queryKey: accountsQueryKeys.walletChainsList(),
    queryFn: async () => {
      const { body } = await getWalletChains();
      return body.data;
    },
    enabled: dto.enabled ?? true,
  });
};

export const getTokenBalances = (dto: GetAccountsWalletBalancesDto) =>
  getRequest<ApiResponse<TokensBalanceDto[]>>(`/accounts/token-balances`, dto);

const _getBalancesOverTime = (dto: GetBalancesOvertimeDto) =>
  getRequest<ApiResponse<BalanceOverTime[]>>(`/analytics/balances-over-time`, dto);
export const useGetBalancesOverTime = (dto: GetBalancesOvertimeDto) => {
  const query = useQuery<BalanceOverTime[], Error>({
    queryKey: accountsQueryKeys.balancesOverTime(dto),
    queryFn: async () => {
      const { body } = await _getBalancesOverTime(dto);
      return body.data;
    },
  });
  return {
    query,
    data: query.data,
    loading: query.isLoading,
  };
};

export const getBalanceChecks = (snapshotGroupId?: string) =>
  getRequest<ApiResponse<BalanceCheck[]>>('/analytics/balance-checks', { snapshotGroupId });

export const getBalanceCheckSnapshotGroups = () =>
  getRequest<ApiResponse<BalanceCheckSnapshotGroup>>('/analytics/balance-checks/groups', {});

export const getAccountSections = () =>
  getRequest<ApiResponse<BalanceCheck[]>>('/accounts/sections', null);
export const useGetAccountSections = () => {
  const query = useQuery<AccountSection[], Error>({
    queryKey: accountsQueryKeys.sections(),
    queryFn: async () => {
      const { body } = await getAccountSections();
      return body.data;
    },
  });
  return {
    query,
    data: query.data,
    loading: query.isLoading,
  };
};

export const createAccountSection = (dto: CreateAccountSectionDto) =>
  postRequest<ApiResponse<AccountSection>>('/accounts/sections', dto);
export const useCreateAccountSection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dto: CreateAccountSectionDto) => {
      const { body } = await createAccountSection(dto);
      return body.data;
    },
    onSuccess: () => {
      // TODO: use setQueryData
      queryClient.invalidateQueries(accountsQueryKeys.sections());
    },
  });
};

export const updateAccountSections = (dto: BulkUpdateAccountSectionDto[]) =>
  patchRequest('/accounts/sections', dto);
export const useUpdateAccountSections = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dto: BulkUpdateAccountSectionDto[]) => {
      const { body } = await updateAccountSections(dto);
      return body.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(accountsQueryKeys.sections());
    },
  });
};

export const updateAccountSection = (id: string, dto: UpdateAccountSectionDto) =>
  patchRequest<ApiResponse<AccountSection>>(`/accounts/sections/${id}`, dto);
export const useUpdateAccountSection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, dto }: { id: string; dto: UpdateAccountSectionDto }) => {
      const { body } = await updateAccountSection(id, dto);
      return body.data;
    },
    onSuccess: () => {
      // TODO: use setQueryData
      queryClient.invalidateQueries(accountsQueryKeys.sections());
    },
  });
};

// NOTE: Only empty sections can be deleted. "Archive" section cannot be deleted.
export const deleteAccountSection = (id: string) =>
  deleteRequest<ApiResponse<AccountSection>>(`/accounts/sections/${id}`, {});
export const useDeleteAccountSection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: string) => {
      deleteAccountSection(id);
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        accountsQueryKeys.sections(),
        (state: AccountSection[] | undefined) => state?.filter((row) => row.id !== variables),
      );
    },
  });
};

const _getDefiBalancesForProtocol = (dto: DefiBalancesQuery) =>
  getRequest<ApiResponse<DefiBalancesForProtocol[]>>('/accounts/defi-balances', dto);
export const useGetDefiBalancesForProtocol = (
  dto: DefiBalancesQuery,
  config: { enabled?: boolean } = {},
) => {
  const { enabled = true } = config;

  return useQuery<DefiBalancesForProtocol[], Error>({
    enabled: enabled && !!dto.date,
    queryKey: accountsQueryKeys.defiBalances(dto),
    queryFn: async () => {
      const { body } = await _getDefiBalancesForProtocol(dto);
      return body.data;
    },
  });
};

const _getDefiBalancesCount = (dto: DefiBalancesQuery) =>
  getRequest<ApiResponse<DefiBalancesCountDto[]>>('/accounts/defi-balances/count', dto);
export const useGetDefiBalancesCount = (
  dto: DefiBalancesQuery,
  config: { enabled?: boolean } = {},
) => {
  const { enabled = true } = config;

  return useQuery<DefiBalancesCountDto, Error>({
    enabled: enabled && !!dto.date,
    queryKey: accountsQueryKeys.defiBalancesCount(dto),
    queryFn: async () => {
      const { body } = await _getDefiBalancesCount(dto);
      return body.data;
    },
  });
};

const _getTokenBalances = (dto: TokenBalancesQuery) =>
  getRequest<ApiResponse<TokenBalanceDto[] | TokenBalanceByDayDto[]>>('/accounts/tokens-view', dto);
export const useGetTokenBalances = (dto: TokenBalancesQuery) => {
  return useQuery<TokenBalanceDto[] | TokenBalanceByDayDto[], Error>({
    enabled: !!dto.to,
    queryKey: accountsQueryKeys.tokenBalances(dto),
    queryFn: async () => {
      const { body } = await _getTokenBalances(dto);
      return dto.isToday ? body.data : get(body.data, '0.tokens') || null;
    },
  });
};

const _getWalletBalances = (dto: TokenBalancesQuery) =>
  getRequest<ApiResponse<Account[] | WalletByDayDto[]>>('/accounts/wallets-view', dto);
export const useGetWalletBalances = (dto: TokenBalancesQuery) => {
  return useQuery<Account[] | WalletByDayDto[], Error>({
    enabled: !!dto.to,
    queryKey: accountsQueryKeys.walletBalances(dto),
    queryFn: async () => {
      const { body } = await _getWalletBalances(dto);
      return dto.isToday ? body.data : get(body.data, '0.accounts') || null;
    },
  });
};

const _getFiatBalancesByCurrency = (dto: FiatBalancesQuery) =>
  getRequest<ApiResponse<FiatBalancesForCurrency[]>>('/accounts/fiat-balances-by-currency', dto);
export const useGetFiatBalancesByCurrency = (dto: FiatBalancesQuery) => {
  return useQuery<FiatBalancesForCurrency[], Error>({
    enabled: !!dto.date,
    queryKey: accountsQueryKeys.fiatBalancesByCurrency(dto),
    queryFn: async () => {
      const { body } = await _getFiatBalancesByCurrency(dto);
      return body.data;
    },
  });
};

const _getFiatBalancesBySource = (dto: FiatBalancesQuery) =>
  getRequest<ApiResponse<FiatBalancesForSource[]>>('/accounts/fiat-balances-by-source', dto);
export const useGetFiatBalancesBySource = (dto: FiatBalancesQuery) => {
  return useQuery<FiatBalancesForSource[], Error>({
    enabled: !!dto.date,
    queryKey: accountsQueryKeys.fiatBalancesBySource(dto),
    queryFn: async () => {
      const { body } = await _getFiatBalancesBySource(dto);
      return body.data;
    },
  });
};
