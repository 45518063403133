import { ThemeConfig } from 'antd';
import tabsConfig from './_tabs';

const componentsTheme: ThemeConfig['components'] = {
  Tabs: tabsConfig,
  Space: {
    paddingXS: 12,
  },
};

export default componentsTheme;
