// @ts-ignore
import * as cssVars from '../styles/variables.module.scss';

const isNumber = new RegExp(/^\d+$/);

const variables: Record<string, string | number> = {};
Object.keys(cssVars).forEach((key) => {
  variables[key] = isNumber.test(cssVars[key]) ? parseFloat(cssVars[key]) : cssVars[key];
});

export const SASS_VARIABLES = variables;
