import { Dictionary } from 'lodash';

export interface BookAccount {
  id: string;
  code?: string | null; // bug: backend is returning `null` here when it shouldn't
  name: string;
  currency: string;
  isBankAccount: boolean;
  currencyBalance: number;
  isEnabled: boolean;
  disabledReason?: string;
  fullyQualifiedName: string;
  fullyQualifiedCategory: string;
}

export interface BookAccountOptions {
  label: string;
  options: { label: string; value: string; name: string; disabled: boolean }[];
}

export interface BookAccountOptions {
  label: string;
  options: { label: string; value: string; name: string; disabled: boolean }[];
}

export interface BAMappingAccount {
  id: string;
  name: string;
  chain: string | null;
  provider: string | null;
  address: string | null;
}

export interface BookAccountMapping {
  id: string;
  type:
    | 'Wallet'
    | 'AllTokens'
    | 'AllCollections'
    | 'Token'
    | 'Collection'
    | 'WalletToken'
    | 'WalletCollection';
  account: BAMappingAccount | null;
  tokenId: number | null;
  nftCollection: string | null;
  bookAccount: string | null;
  symbol: string | null;
  name: string | null;
  iconUrl: string | null;
  parent: string;
}

export interface UpdateBookAccountMappingDto {
  id: string;
  bookAccount: string | null;
}

export interface BAMappingCounts {
  unmappedCount: number;
  totalAssets: number;
}

export interface BookAccountMappingRow extends BookAccountMapping {
  dataPath: string[];
}

export interface UseBookAccountsData {
  byId: Record<string, BookAccount>;
  list: BookAccount[];
  // Grouped by the GL Types (e.g. Expense, Asset etc.)
  groupedByGLType: Dictionary<BookAccount[]>;
}
export const BOOK_ACCOUNT_ARCHIVED_GROUP_NAME = '__ARCHIVED__';
