import React, { useCallback, useMemo } from 'react';

// Internal
import { getEnabledFeatures } from '@/api/features';

type EnabledFeaturesProviderProps = { children: React.ReactNode };

export enum FeatureFlag {
  ImpairmentPolicyCreation = 'ImpairmentPolicyCreation',
  SyncNftInventoryWorkflow = 'SyncNftInventoryWorkflow',
  NftTransaction = 'NftTransaction',
  NftRoyalty = 'NftRoyalty',
  NftValuationDeepNftValue = 'NftValuation-deepNftValue',
  OneTimeBill = 'OneTimeBill',
  BalanceChecks = 'BalanceChecks',
  BalanceCheckSnapshot = 'BalanceCheckSnapshot',
  RippleSupport = 'RippleSupport',
  TxnBundlingV2 = 'TxnBundlingV2',
  DefiTax = 'DefiTax',
  Defi = 'Defi',
  CosmosIntegration = 'CosmosIntegration',
  Fiat = 'Fiat',
  BKV2EarlyAccess = 'BKV2EarlyAccess',
  RollForwardReportV2 = 'RollForwardReportV2',
  ClosingPositionsExportOnly = 'ClosingPositionsExportOnly', // prevent rendering of the table and only show the Export button
  TreasuryShowPnlTab = 'TreasuryShowPnlTab',
  BKV1ShowClassifiedTransactions = 'BKV1ShowClassifiedTransactions',
  SolanaSyncAccountStakeAccounts = 'SolanaSyncAccountStakeAccounts',
  TaxLotSafeHaven = 'TaxLotSafeHaven', // Tax lot safe haven global switch - stick to the old workflow until it's enabled
}

const EnabledFeaturesStateContext = React.createContext<
  | {
      enabledFeatures: string[] | null;
      reFetchEnabledFeatures: Function;
      loading: boolean;
      isImpairmentEnabled: boolean;
      isBalanceChecksEnabled: boolean;
      // Balance check audit trail
      isBalanceCheckSnapshotsEnabled: boolean;
      isRippleSupportEnabled: boolean;
      // NFTs
      isNftEnabled: boolean;
      isNftRoyaltyEnabled: boolean;
      isNftTxnEnabled: boolean;
      isDeepNftValueEnabled: boolean;
      // Bills
      isOneTimeBillsEnabled: boolean;
      // Bookkeeping v2
      isBookkeepingV2Enabled: boolean | null;
      // DeFi
      isDefiEnabled: boolean | null;
      isDefiTaxEnabled: boolean | null;
      //Cosmos Integration
      isCosmosIntegrationEnabled: boolean | null;
      // Fiat/Plaid
      isFiatEnabled: boolean | null;
      // Reports
      isRollForwardReportV2Enabled: boolean | null;
      isClosingPositionsExportOnlyEnabled: boolean | null;
      // Treasury
      isTreasuryShowPnlTabEnabled: boolean | null;
      // Bookkeeping v1
      isBKV1ShowClassifiedTransactionsEnabled: boolean | null;
      // Solana Sub Stake Accounts
      isSolanaSyncAccountStakeAccountsEnabled: boolean | null;
      // Cost Basis
      isTaxLotSafeHavenEnabled: boolean;
    }
  | undefined
>(undefined);

function EnabledFeaturesProvider({ children }: EnabledFeaturesProviderProps) {
  const [enabledFeatures, setEnabledFeatures] = React.useState<string[] | null>(null);
  const [loading, setLoading] = React.useState(false);

  const fetchEnabledFeatures = useCallback(() => {
    setLoading(true);

    getEnabledFeatures()
      .then((features) => {
        setEnabledFeatures(features);
      })
      .catch((err) => {
        console.error('Error fetching enabled features', err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(fetchEnabledFeatures, [fetchEnabledFeatures]);

  const isImpairmentEnabled = useMemo(() => {
    if (!Array.isArray(enabledFeatures)) {
      return false;
    }

    return enabledFeatures?.includes(FeatureFlag.ImpairmentPolicyCreation);
  }, [enabledFeatures]);

  const isNftEnabled = useMemo(() => {
    if (!Array.isArray(enabledFeatures)) {
      return false;
    }

    return enabledFeatures?.includes(FeatureFlag.SyncNftInventoryWorkflow);
  }, [enabledFeatures]);

  const isNftTxnEnabled = useMemo(() => {
    if (!Array.isArray(enabledFeatures)) {
      return false;
    }

    return enabledFeatures?.includes(FeatureFlag.NftTransaction);
  }, [enabledFeatures]);

  const isNftRoyaltyEnabled = useMemo(() => {
    if (!Array.isArray(enabledFeatures)) {
      return false;
    }

    return enabledFeatures?.includes(FeatureFlag.NftRoyalty);
  }, [enabledFeatures]);

  const isDeepNftValueEnabled = useMemo(() => {
    if (!Array.isArray(enabledFeatures)) {
      return false;
    }

    return enabledFeatures?.includes(FeatureFlag.NftValuationDeepNftValue);
  }, [enabledFeatures]);

  const isOneTimeBillsEnabled = useMemo(() => {
    if (!Array.isArray(enabledFeatures)) {
      return false;
    }

    return enabledFeatures?.includes(FeatureFlag.OneTimeBill);
  }, [enabledFeatures]);

  const isBalanceChecksEnabled = useMemo(() => {
    if (!Array.isArray(enabledFeatures)) {
      return false;
    }

    return enabledFeatures?.includes(FeatureFlag.BalanceChecks);
  }, [enabledFeatures]);

  const isBalanceCheckSnapshotsEnabled = useMemo(() => {
    if (!Array.isArray(enabledFeatures)) {
      return false;
    }

    return enabledFeatures?.includes(FeatureFlag.BalanceCheckSnapshot);
  }, [enabledFeatures]);

  const isRippleSupportEnabled = useMemo(() => {
    if (!Array.isArray(enabledFeatures)) {
      return false;
    }

    return enabledFeatures?.includes(FeatureFlag.RippleSupport);
  }, [enabledFeatures]);

  const isBookkeepingV2Enabled = useMemo(() => {
    if (enabledFeatures === null) {
      return null;
    }

    return (
      (enabledFeatures || [])?.includes(FeatureFlag.TxnBundlingV2) &&
      (enabledFeatures || [])?.includes(FeatureFlag.BKV2EarlyAccess)
    );
  }, [enabledFeatures]);

  const isDefiTaxEnabled = useMemo(() => {
    if (enabledFeatures === null) {
      return null;
    }

    return (enabledFeatures || [])?.includes(FeatureFlag.DefiTax);
  }, [enabledFeatures]);

  const isDefiEnabled = useMemo(() => {
    if (enabledFeatures === null) {
      return null;
    }

    return (enabledFeatures || [])?.includes(FeatureFlag.Defi);
  }, [enabledFeatures]);

  const isCosmosIntegrationEnabled = useMemo(() => {
    if (enabledFeatures === null) {
      return null;
    }

    return (enabledFeatures || [])?.includes(FeatureFlag.CosmosIntegration);
  }, [enabledFeatures]);

  const isFiatEnabled = useMemo(() => {
    if (enabledFeatures === null) {
      return null;
    }

    return (enabledFeatures || [])?.includes(FeatureFlag.Fiat);
  }, [enabledFeatures]);

  const isRollForwardReportV2Enabled = useMemo(() => {
    if (enabledFeatures === null) {
      return null;
    }

    return (enabledFeatures || [])?.includes(FeatureFlag.RollForwardReportV2);
  }, [enabledFeatures]);

  const isClosingPositionsExportOnlyEnabled = useMemo(() => {
    if (enabledFeatures === null) {
      return null;
    }

    return (enabledFeatures || [])?.includes(FeatureFlag.ClosingPositionsExportOnly);
  }, [enabledFeatures]);

  const isTreasuryShowPnlTabEnabled = useMemo(() => {
    if (enabledFeatures === null) {
      return null;
    }

    return (enabledFeatures || [])?.includes(FeatureFlag.TreasuryShowPnlTab);
  }, [enabledFeatures]);

  const isBKV1ShowClassifiedTransactionsEnabled = useMemo(() => {
    return (enabledFeatures || [])?.includes(FeatureFlag.BKV1ShowClassifiedTransactions);
  }, [enabledFeatures]);

  const isSolanaSyncAccountStakeAccountsEnabled = useMemo(() => {
    if (enabledFeatures === null) {
      return null;
    }

    return (enabledFeatures || [])?.includes(FeatureFlag.SolanaSyncAccountStakeAccounts);
  }, [enabledFeatures]);

  const isTaxLotSafeHavenEnabled = useMemo(() => {
    if (!Array.isArray(enabledFeatures)) {
      return false;
    }

    return enabledFeatures?.includes(FeatureFlag.TaxLotSafeHaven);
  }, [enabledFeatures]);

  const value = {
    enabledFeatures,
    reFetchEnabledFeatures: fetchEnabledFeatures,
    loading,
    isImpairmentEnabled,
    isBalanceChecksEnabled,
    isBalanceCheckSnapshotsEnabled,
    isRippleSupportEnabled,
    // NFTs
    isNftEnabled,
    isNftRoyaltyEnabled,
    isNftTxnEnabled,
    isDeepNftValueEnabled,
    // Bills
    isOneTimeBillsEnabled,
    // Bookkeeping v2
    isBookkeepingV2Enabled,
    // DeFi
    isDefiEnabled,
    isDefiTaxEnabled,
    //Cosmos integration
    isCosmosIntegrationEnabled,
    // Fiat/Plaid
    isFiatEnabled,
    // Reports
    isRollForwardReportV2Enabled,
    isClosingPositionsExportOnlyEnabled,
    // Treasury
    isTreasuryShowPnlTabEnabled,
    // Bookkeeping v1
    isBKV1ShowClassifiedTransactionsEnabled,

    // Solana Stake Accounts
    isSolanaSyncAccountStakeAccountsEnabled,

    // Cost basis
    isTaxLotSafeHavenEnabled,
  };

  return (
    <EnabledFeaturesStateContext.Provider value={value}>
      {children}
    </EnabledFeaturesStateContext.Provider>
  );
}

function useEnabledFeatures() {
  const context = React.useContext(EnabledFeaturesStateContext);
  if (context === undefined) {
    throw new Error('useEnabledFeatures must be used within a EnabledFeaturesProvider');
  }

  return context;
}

export { EnabledFeaturesProvider, useEnabledFeatures };
